import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const navigation = [
  { name: 'Услуги', path: '/services' },
  { name: 'О нас', path: '/about' },
  { name: 'Кофе с маркетологом', path: '/coffee-marketing' },
  { name: 'Кейсы', path: '/cases' },
  { name: 'Блог', path: '/blog' },
  { name: 'Контакты', path: '/contact' }
];

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuOpen]);

  return (
    <header className={`header ${scrolled ? 'scrolled' : ''} ${isMenuOpen ? 'menu-open' : ''}`}>
      <div className="header-container">
        <Link to="/" className="header-logo">
          <span className="logo-text">
            <span className="text-caramel">Эс</span>Пресс
          </span>
        </Link>

        <button 
          className={`burger-button ${isMenuOpen ? 'active' : ''}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Открыть меню"
        >
          <span className="burger-line"></span>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
        </button>

        <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
          <div className="nav-links">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className="nav-link"
                onClick={() => setIsMenuOpen(false)}
              >
                <span className="nav-text">{item.name}</span>
              </Link>
            ))}
          </div>

          <Link 
            to="/dashboard" 
            className="cta-button"
            onClick={() => setIsMenuOpen(false)}
          >
            <span className="cta-text">Написать бриф</span>
            <span className="cta-border"></span>
          </Link>
        </nav>

        {isMenuOpen && (
          <div 
            className="menu-overlay" 
            onClick={() => setIsMenuOpen(false)}
          />
        )}
      </div>
    </header>
  );
};

export default Header;