// src/pages/CoffeeMarketing/components/ProcessSection/index.jsx
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './ProcessSection.css';

gsap.registerPlugin(ScrollTrigger);

const STEPS = [
  {
    id: 1,
    title: 'Вводная консультация',
    description: 'Нужна для того, чтобы познакомиться, получше узнать о вашей задаче. Она длится 30 минут и бесплатна.',
    icon: (
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <circle cx="12" cy="12" r="10"/>
        <path d="M12 16v-4"/>
        <path d="M12 8h.01"/>
      </svg>
    )
  },
  {
    id: 2,
    title: 'Процесс подготовки',
    description: 'На подготовку ко встрече отводится 1 неделя. Мы глубоко погружаемся в проект, анализируем и создаем стратегию.',
    icon: (
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
      </svg>
    )
  },
  {
    id: 3,
    title: 'Встреча',
    description: 'Встреча проходит в коворкинге или в Zoom, длится 2 часа. Вы можете задать все вопросы после встречи. На тарифе "ЭсПресс лонг" Анна будет на связи в течении месяца.',
    icon: (
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"/>
        <circle cx="9" cy="7" r="4"/>
        <path d="M23 21v-2a4 4 0 00-3-3.87"/>
        <path d="M16 3.13a4 4 0 010 7.75"/>
      </svg>
    )
  },
  {
    id: 4,
    title: 'Помощь в реализации',
    description: 'На тарифе ЭсПресс Лонг доступна возможность задавать вопросы в процессе реализации стратегии. А также, часть работ или полный комплекс услуг может взять на себя команда ЭсПресс, что оговаривается отдельно уже после встречи.',
    icon: (
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/>
        <circle cx="12" cy="12" r="3"/>
      </svg>
    )
  }
];

const ProcessSection = () => {
  const cardsRef = useRef([]);

  useEffect(() => {
    const cards = cardsRef.current;
    cards.forEach((card, index) => {
      if (!card) return;

      ScrollTrigger.create({
        trigger: card,
        start: 'top 80%',
        once: true,
        onEnter: () => {
          card.classList.add('visible');
        }
      });

      // 3D эффект при наведении
      const handleMouseMove = (e) => {
        const rect = card.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;
        
        gsap.to(card, {
          rotateX: (y - centerY) / 20,
          rotateY: -(x - centerX) / 20,
          translateZ: '10px',
          duration: 0.3,
          ease: 'power2.out'
        });
      };

      const handleMouseLeave = () => {
        gsap.to(card, {
          rotateX: 0,
          rotateY: 0,
          translateZ: 0,
          duration: 0.5,
          ease: 'power2.out'
        });
      };

      card.addEventListener('mousemove', handleMouseMove);
      card.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        card.removeEventListener('mousemove', handleMouseMove);
        card.removeEventListener('mouseleave', handleMouseLeave);
      };
    });
  }, []);

  return (
    <section className="process-section">
      <div className="floating-dots">
        {[1, 2, 3].map(i => (
          <div key={i} className="dot" />
        ))}
      </div>

      <div className="process-container">
        <div className="process-header">
          <h2 className="process-title">Как будет организована наша встреча?</h2>
          <p className="process-subtitle">Оставьте заявку на вводную консультацию</p>
        </div>

        <div className="steps-container">
          <div className="timeline-line" />
          
          {STEPS.map((step, index) => (
            <div
              key={step.id}
              ref={el => cardsRef.current[index] = el}
              className={`step-card ${step.id === 4 ? 'implementation' : ''}`}
            >
              <div className="step-connector" />
              <div className="step-number">{step.id}</div>
              <div className="step-content">
                <div className="step-icon">{step.icon}</div>
                <h3 className="step-title">{step.title}</h3>
                <p className="step-description">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProcessSection;