import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { 
  MapPin, Phone, Mail, Clock, 
  MessageCircle, Send, Coffee 
} from 'lucide-react';
import { Button } from '../../components/common/Button/Button';
import './Contact.css';

const contactMethods = [
  {
    icon: Phone,
    title: 'Телефон',
    value: '+7 (999) 999-99-99',
    link: 'tel:+79999999999',
    description: 'Пн-Пт с 9:00 до 18:00'
  },
  {
    icon: Mail,
    title: 'Email',
    value: 'info@espress.ru',
    link: 'mailto:info@espress.ru',
    description: 'Ответим в течение 2 часов'
  },
  {
    icon: MessageCircle,
    title: 'Telegram',
    value: '@espress_marketing',
    link: 'https://t.me/espress_marketing',
    description: 'Самый быстрый способ связи'
  }
];

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    topic: 'consultation',
    message: ''
  });

  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    // Здесь будет логика отправки формы
    await new Promise(resolve => setTimeout(resolve, 1000));
    setSubmitting(false);
    // Сброс формы или показ сообщения об успехе
  };

  return (
    <>
      <Helmet>
        <title>Контакты - ЭсПресс Маркетинг</title>
        <meta 
          name="description" 
          content="Свяжитесь с нами для консультации по вопросам маркетинга и развития бизнеса" 
        />
      </Helmet>

      <div className="contact-page">
        {/* Hero секция */}
        <section className="contact-hero">
          <div className="container">
            <h1>Свяжитесь с нами</h1>
            <p className="hero-subtitle">
              Выберите удобный способ связи или оставьте заявку, и мы свяжемся с вами сами
            </p>

            {/* Способы связи */}
            <div className="contact-methods">
              {contactMethods.map((method, index) => (
                <a 
                  key={index}
                  href={method.link}
                  className="contact-method-card"
                  target={method.link.startsWith('http') ? '_blank' : '_self'}
                  rel={method.link.startsWith('http') ? 'noopener noreferrer' : ''}
                >
                  <div className="method-icon">
                    <method.icon size={24} />
                  </div>
                  <div className="method-content">
                    <h3>{method.title}</h3>
                    <div className="method-value">{method.value}</div>
                    <div className="method-description">{method.description}</div>
                  </div>
                  <Send className="send-icon" size={16} />
                </a>
              ))}
            </div>
          </div>
        </section>

        {/* Основной контент */}
        <section className="contact-content">
          <div className="container">
            <div className="contact-grid">
              {/* Форма обратной связи */}
              <div className="contact-form-wrapper">
                <div className="form-header">
                  <Coffee className="form-icon" size={32} />
                  <div>
                    <h2>Оставить заявку</h2>
                    <p>Заполните форму, и мы свяжемся с вами в течение 2 часов</p>
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="contact-form">
                  <div className="form-grid">
                    <div className="form-group">
                      <label htmlFor="name">Ваше имя</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Как к вам обращаться"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="phone">Телефон</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="+7 (___) ___-__-__"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="example@domain.com"
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="topic">Тема обращения</label>
                      <select
                        id="topic"
                        name="topic"
                        value={formData.topic}
                        onChange={handleChange}
                      >
                        <option value="consultation">Консультация</option>
                        <option value="website">Разработка сайта</option>
                        <option value="smm">Ведение соцсетей</option>
                        <option value="other">Другое</option>
                      </select>
                    </div>

                    <div className="form-group full-width">
                      <label htmlFor="message">Сообщение</label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Опишите ваш запрос"
                        rows={4}
                      />
                    </div>
                  </div>

                  <Button 
                    type="submit" 
                    className="submit-button"
                    disabled={submitting}
                  >
                    {submitting ? 'Отправка...' : 'Отправить заявку'}
                    <Send size={16} />
                  </Button>
                </form>
              </div>

              {/* Информация о местоположении */}
              <div className="location-info">
                <div className="info-card">
                  <h3>
                    <MapPin className="info-icon" />
                    Наш офис
                  </h3>
                  <p className="address">
                    г. Москва, ул. Примерная, д. 123, офис 45
                  </p>
                  <div className="work-hours">
                    <Clock className="info-icon" />
                    <div>
                      <h4>Часы работы:</h4>
                      <p>Пн-Пт: 9:00 - 18:00</p>
                      <p>Сб-Вс: Выходной</p>
                    </div>
                  </div>
                  <Button 
                    variant="outline" 
                    className="map-button"
                    onClick={() => window.open('https://yandex.ru/maps', '_blank')}
                  >
                    Открыть на карте
                    <MapPin size={16} />
                  </Button>
                </div>

                {/* Здесь будет карта */}
                <div className="map-placeholder">
                  <div className="map-overlay">
                    <Button onClick={() => window.open('https://yandex.ru/maps', '_blank')}>
                      Загрузить карту
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ секция */}
        <section className="faq-section">
          <div className="container">
            <h2>Часто задаваемые вопросы</h2>
            <div className="faq-grid">
              <div className="faq-card">
                <h3>Сколько стоят услуги?</h3>
                <p>Стоимость зависит от объема работ и выбранного тарифа. Минимальный бюджет для начала работ - от 30 000 рублей.</p>
              </div>
              <div className="faq-card">
                <h3>Как происходит оплата?</h3>
                <p>Работаем по предоплате 50%. Оставшаяся сумма вносится после завершения работ.</p>
              </div>
              <div className="faq-card">
                <h3>Сколько времени занимает работа?</h3>
                <p>Сроки зависят от сложности проекта. Минимальный срок разработки - 2 недели.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactPage;