import React from 'react';
import { LineChart, CheckSquare, GraduationCap } from 'lucide-react';

const methodologies = [
  {
    title: 'Прозрачность',
    icon: LineChart,
    features: [
      'Доступ к панели статистики 24/7',
      'Еженедельные отчеты в PDF',
      'График работ в Notion',
      'Чат с командой проекта'
    ],
    color: 'caramel'
  },
  {
    title: 'Системность',
    icon: CheckSquare,
    features: [
      'Пошаговые чек-листы',
      'Регулярная аналитика',
      'Документация процессов',
      'Измеримые KPI'
    ],
    color: 'dark-coffee'
  },
  {
    title: 'Развитие',
    icon: GraduationCap,
    features: [
      'Обучение команды',
      'Передача инструментов',
      'База знаний проекта',
      'Поддержка после внедрения'
    ],
    color: 'latte'
  }
];

const Methodology = () => {
  return (
    <section id="methodology" className="py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-2xl mx-auto mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-dark-coffee mb-6">
            Методология
          </h2>
          <p className="text-lg text-latte">
            Наш подход к решению маркетинговых задач
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {methodologies.map((item, index) => (
            <div key={index} className="group">
              <div className="relative h-full">
                {/* Декоративный фоновый элемент */}
                <div className="absolute -inset-4 bg-gradient-to-b from-cream/50 to-transparent rounded-3xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                
                {/* Основная карточка */}
                <div className="relative h-full p-8 rounded-2xl bg-white shadow-lg border border-latte/10">
                  {/* Иконка с фоном */}
                  <div className={`w-16 h-16 rounded-2xl bg-${item.color}/10 flex items-center justify-center mb-6 transform group-hover:scale-110 transition-transform duration-300`}>
                    <item.icon className={`w-8 h-8 text-${item.color}`} />
                  </div>
                  
                  {/* Заголовок */}
                  <h3 className="text-xl font-semibold text-dark-coffee mb-6">
                    {item.title}
                  </h3>

                  {/* Список преимуществ */}
                  <ul className="space-y-4">
                    {item.features.map((feature, idx) => (
                      <li key={idx} className="flex items-start text-latte">
                        <span className="w-1.5 h-1.5 rounded-full bg-caramel mr-3 mt-2 flex-shrink-0" />
                        <span className="group-hover:text-dark-coffee transition-colors duration-300">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Декоративные элементы */}
        <div className="absolute left-0 top-1/2 -translate-y-1/2 w-32 h-32 bg-caramel/5 rounded-full blur-3xl" />
        <div className="absolute right-0 bottom-0 w-48 h-48 bg-latte/5 rounded-full blur-3xl" />
      </div>
    </section>
  );
};

export default Methodology;