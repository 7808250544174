import React from 'react';
import { SearchCheck, BarChart2, Rocket, TrendingUp } from 'lucide-react';

const processes = [
  {
    title: 'Аналитика',
    icon: SearchCheck,
    items: [
      'Анализ ниши за 5 дней',
      'Исследование конкурентов',
      'Аудит текущих инструментов',
      'Поиск точек роста'
    ],
    color: 'caramel'
  },
  {
    title: 'Стратегия',
    icon: BarChart2,
    items: [
      'Выбор каналов продвижения',
      'Разработка воронок продаж',
      'Расчет unit-экономики',
      'План внедрения на 3 месяца'
    ],
    color: 'dark-coffee'
  },
  {
    title: 'Внедрение',
    icon: Rocket,
    items: [
      'Запуск каналов за 2 недели',
      'Настройка аналитики',
      'Отслеживание метрик',
      'Еженедельные корректировки'
    ],
    color: 'latte'
  },
  {
    title: 'Развитие',
    icon: TrendingUp,
    items: [
      'Масштабирование работающих каналов',
      'Тестирование новых инструментов',
      'Автоматизация процессов',
      'Обучение команды'
    ],
    color: 'coffee-milk'
  }
];

const WorkProcess = () => {
  return (
    <section id="work-process" className="py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-2xl mx-auto mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-dark-coffee mb-6">
            Процесс работы
          </h2>
          <p className="text-lg text-latte">
            Прозрачный и эффективный подход к развитию вашего бизнеса
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {processes.map((process, index) => (
            <div
              key={index}
              className="relative group"
            >
              <div className="absolute -inset-2 bg-gradient-to-b from-cream to-transparent rounded-3xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              
              <div className="relative p-8 rounded-2xl bg-white shadow-lg border border-latte/10">
                <div className="flex items-center mb-6">
                  <div className={`w-12 h-12 rounded-full bg-${process.color}/10 flex items-center justify-center mr-4`}>
                    <process.icon className={`w-6 h-6 text-${process.color}`} />
                  </div>
                  <div className="flex items-baseline">
                    <span className="text-sm text-latte mr-2">{String(index + 1).padStart(2, '0')}</span>
                    <h3 className="text-xl font-semibold text-dark-coffee">
                      {process.title}
                    </h3>
                  </div>
                </div>

                <ul className="space-y-4">
                  {process.items.map((item, idx) => (
                    <li key={idx} className="flex items-start text-latte">
                      <span className="w-1.5 h-1.5 rounded-full bg-caramel mr-3 mt-2" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/* Connecting lines for desktop */}
        <div className="hidden lg:block relative mt-8">
          <div className="absolute top-0 left-[25%] right-[25%] h-0.5 bg-gradient-to-r from-caramel via-dark-coffee to-latte opacity-20" />
        </div>
      </div>
    </section>
  );
};

export default WorkProcess;