// src/pages/CoffeeMarketing/components/PricingSection/index.jsx
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './PricingSection.css';

gsap.registerPlugin(ScrollTrigger);

const PRICING_PLANS = [
  {
    id: 'espresso',
    title: 'ЭсПресс',
    subtitle: 'Консультация онлайн',
    price: '7 481',
    period: 'разовая консультация',
    features: [
      'Предварительная консультация (30 минут)',
      '2-х часовая встреча в Zoom',
      'Наглядный файл со стратегией и инструментами',
      'Ответы на вопросы в процессе и после встречи'
    ],
    icon: (
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M18 8h-1V6c0-2.21-1.79-4-4-4H7C4.79 2 3 3.79 3 6v12c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-2h1c2.21 0 4-1.79 4-4s-1.79-4-4-4z"/>
        <path d="M12 10a2 2 0 100-4 2 2 0 000 4z"/>
      </svg>
    ),
    buttonIcon: (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M15 10l5 5-5 5M4 4v7a4 4 0 004 4h12"/>
      </svg>
    )
  },
  {
    id: 'author',
    title: 'ЭсПресс в авторской чашке',
    subtitle: 'Офлайн консультация',
    price: '8 481',
    period: 'разовая консультация',
    features: [
      'Предварительная консультация (30 минут)',
      '2-х часовая встреча в коворкинге',
      'Наглядный файл со стратегией и инструментами',
      'Ответы на вопросы в процессе и после встречи',
      'Чашка ароматного кофе или чая'
    ],
    icon: (
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M18 8h-1V6c0-2.21-1.79-4-4-4H7C4.79 2 3 3.79 3 6v12c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-2h1c2.21 0 4-1.79 4-4s-1.79-4-4-4z"/>
        <circle cx="12" cy="10" r="3"/>
      </svg>
    ),
    buttonIcon: (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
        <path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
      </svg>
    ),
    badge: 'Популярный'
  },
  {
    id: 'long',
    title: 'ЭсПресс лонг',
    subtitle: 'Консультация + сопровождение',
    price: '14 481',
    period: 'консультация + месяц поддержки',
    features: [
      'Все преимущества тарифа "ЭсПресс в авторской чашке"',
      'Сопровождение в течение 1 месяца в личном чате телеграм',
      'Ответы на 3 вопроса в неделю'
    ],
    icon: (
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M18 8h-1V6c0-2.21-1.79-4-4-4H7C4.79 2 3 3.79 3 6v12c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-2h1c2.21 0 4-1.79 4-4s-1.79-4-4-4z"/>
        <path d="M12 8v8M8 12h8"/>
      </svg>
    ),
    buttonIcon: (
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
        <path d="M22 6l-10 7L2 6"/>
      </svg>
    )
  }
];

const PricingSection = () => {
  const cardsRef = useRef([]);

  useEffect(() => {
    const cards = cardsRef.current;
    
    cards.forEach((card, index) => {
      if (!card) return;

      // 3D эффект при наведении
      const handleMouseMove = (e) => {
        const rect = card.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;
        
        gsap.to(card, {
          rotateX: (y - centerY) / 20,
          rotateY: -(x - centerX) / 20,
          translateZ: '20px',
          duration: 0.3,
          ease: 'power2.out'
        });
      };

      const handleMouseLeave = () => {
        gsap.to(card, {
          rotateX: 0,
          rotateY: 0,
          translateZ: 0,
          duration: 0.5,
          ease: 'power2.out'
        });
      };

      // Анимация появления
      ScrollTrigger.create({
        trigger: card,
        start: "top 80%",
        onEnter: () => {
          gsap.to(card, {
            y: 0,
            opacity: 1,
            duration: 0.8,
            delay: index * 0.2
          });
        }
      });

      card.addEventListener('mousemove', handleMouseMove);
      card.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        card.removeEventListener('mousemove', handleMouseMove);
        card.removeEventListener('mouseleave', handleMouseLeave);
      };
    });
  }, []);

  return (
    <div className="pricing-grid">
      {PRICING_PLANS.map((plan, index) => (
        <div
          key={plan.id}
          ref={el => cardsRef.current[index] = el}
          className={`pricing-card ${plan.id}`}
        >
          {plan.badge && <div className="pricing-badge">{plan.badge}</div>}
          <div className="pricing-image">{plan.icon}</div>
          <h3 className="pricing-title">{plan.title}</h3>
          <p className="pricing-subtitle">{plan.subtitle}</p>
          <div className="pricing-price-wrapper">
            <div className="pricing-price">{plan.price} ₽</div>
            <div className="pricing-period">{plan.period}</div>
          </div>
          <ul className="pricing-features">
            {plan.features.map((feature, i) => (
              <li key={i} className="pricing-feature">
                {feature}
              </li>
            ))}
          </ul>
          <button
            className="pricing-button"
            onClick={() => window.open('https://t.me/annatelepan', '_blank')}
          >
            {plan.buttonIcon}
            Обсудить проект
          </button>
        </div>
      ))}
    </div>
  );
};

export default PricingSection;