import React from 'react';
import { Helmet } from 'react-helmet-async';

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>Личный кабинет - ЭсПресс Маркетинг</title>
        <meta 
          name="description" 
          content="Личный кабинет клиента ЭсПресс Маркетинг" 
        />
      </Helmet>

      <div className="min-h-screen py-20">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold text-dark-coffee">Личный кабинет</h1>
          {/* Здесь будет контент личного кабинета */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;