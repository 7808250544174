import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar, ArrowUpRight } from 'lucide-react';

// Временные данные для примера
const news = [
  {
    id: '1',
    title: 'Запуск нового тарифа "ЭсПресс двойной лонг"',
    description: 'Теперь в тариф входит 3 занятия по работе с инструментами маркетинга и расширенная поддержка',
    image: '/api/placeholder/800/400',
    date: '2024-03-20',
    category: 'Обновления',
    tags: ['Тарифы', 'Обучение', 'Поддержка']
  },
  {
    id: '2',
    title: 'Обновление личного кабинета',
    description: 'Добавили новые функции аналитики и улучшили интерфейс для удобства работы',
    image: '/api/placeholder/800/400',
    date: '2024-03-15',
    category: 'Разработка',
    tags: ['Личный кабинет', 'Аналитика']
  },
  // Добавьте больше новостей...
];

const NewsList = () => {
  const featuredNews = news[0]; // Первая новость будет главной
  const otherNews = news.slice(1);

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      {/* Заголовок */}
      <div className="text-center max-w-2xl mx-auto mb-16">
        <h1 className="text-3xl md:text-4xl font-bold text-dark-coffee mb-6">
          Новости и обновления
        </h1>
        <p className="text-lg text-latte">
          Следите за развитием нашего агентства и новыми возможностями
        </p>
      </div>

      {/* Главная новость */}
      <div className="mb-16">
        <Link
          to={`/news/${featuredNews.id}`}
          className="group block relative rounded-2xl overflow-hidden"
        >
          <div className="aspect-[2/1] relative">
            {/* Изображение */}
            <img
              src={featuredNews.image}
              alt={featuredNews.title}
              className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
            />
            {/* Градиент */}
            <div className="absolute inset-0 bg-gradient-to-t from-dark-coffee/90 to-transparent" />
            
            {/* Контент */}
            <div className="absolute inset-0 flex flex-col justify-end p-8">
              <div className="flex items-center space-x-4 mb-4">
                <span className="px-3 py-1 bg-white/90 backdrop-blur-sm rounded-full text-sm text-dark-coffee">
                  {featuredNews.category}
                </span>
                <span className="flex items-center text-sm text-white/90">
                  <Calendar className="w-4 h-4 mr-2" />
                  {new Date(featuredNews.date).toLocaleDateString('ru-RU')}
                </span>
              </div>
              <h2 className="text-2xl md:text-3xl font-bold text-white mb-4 group-hover:text-caramel transition-colors">
                {featuredNews.title}
              </h2>
              <p className="text-white/80">
                {featuredNews.description}
              </p>
            </div>
          </div>
        </Link>
      </div>

      {/* Остальные новости */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {otherNews.map((item) => (
          <Link
            key={item.id}
            to={`/news/${item.id}`}
            className="group"
          >
            <article className="h-full bg-white rounded-2xl shadow-lg overflow-hidden transition-transform duration-300 group-hover:-translate-y-2">
              {/* Изображение */}
              <div className="aspect-[2/1] overflow-hidden">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
              </div>

              {/* Контент */}
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <span className="px-3 py-1 bg-cream/50 rounded-full text-sm text-dark-coffee">
                    {item.category}
                  </span>
                  <time className="text-sm text-latte">
                    {new Date(item.date).toLocaleDateString('ru-RU')}
                  </time>
                </div>

                <h3 className="text-xl font-semibold text-dark-coffee mb-3 group-hover:text-caramel transition-colors">
                  {item.title}
                </h3>
                <p className="text-latte mb-4 line-clamp-2">
                  {item.description}
                </p>

                {/* Теги */}
                <div className="flex flex-wrap gap-2 mb-4">
                  {item.tags.map((tag) => (
                    <span
                      key={tag}
                      className="text-sm text-latte bg-cream/30 px-2 py-1 rounded-full"
                    >
                      {tag}
                    </span>
                  ))}
                </div>

                {/* Кнопка "Читать" */}
                <div className="flex justify-end">
                  <span className="inline-flex items-center text-caramel group-hover:text-dark-coffee transition-colors">
                    Читать
                    <ArrowUpRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                  </span>
                </div>
              </div>
            </article>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NewsList;