import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Стили Swiper
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

// Локальные стили
import './ServicesSection.css';

const SERVICES = [
  {
    id: 'strategy',
    title: 'Анализ и стратегия',
    description: 'Проведу анализ рекламных кабинетов, поведения ключевых игроков на рынке, систем аналитики, спроса, ключевых ставок в нише и ещё более 30 метрик.',
    image: 'https://static.tildacdn.com/tild3936-3065-4539-b430-373636353031/acetone-2024730-2026.png'
  },
  {
    id: 'questions',
    title: 'Ответы на вопросы',
    description: 'На встрече отвечу на ваши вопросы. А на тарифе "ЭсПресс лонг" буду на связи в течении месяца.',
    image: 'https://static.tildacdn.com/tild3363-3938-4236-a334-623032613566/acetone-202486-13445.png'
  },
  {
    id: 'materials',
    title: 'Материалы после встречи',
    description: 'PDF файл с описанием анализа и стратегии, список необходимых сервисов и практические кейсы из первых рук.',
    image: 'https://static.tildacdn.com/tild3865-3935-4737-b666-376437393034/image_26_1_1.png'
  },
  {
    id: 'consultation',
    title: '2 часа о проекте',
    description: 'В приятной атмосфере, за чашкой ароматного кофе, обсудим проведенный анализ, стратегию и выстроим пошаговый план.',
    image: 'https://static.tildacdn.com/tild3761-6634-4036-a531-366666623230/image_62_1.png'
  },
  {
    id: 'analysis',
    title: 'Практическое обучение',
    description: 'За 2 часа консультации находим минимум 5 точек роста вашего бизнеса. Работающие инструменты для немедленного применения.',
    image: 'https://static.tildacdn.com/tild3134-3562-4761-b136-623466313161/acetone-2024731-1349.png'
  },
  {
    id: 'support',
    title: 'Сопровождение и поддержка',
    description: 'На тарифе ЭсПресс Лонг доступна поддержка после встречи. Это возможность задавать вопросы в процессе реализации стратегии. За руку идем к высотам.',
    isSvg: true
  }
];

const SupportIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
    <path d="M12 1v2M12 21v2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.22 4.22l1.42 1.42" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.36 18.36l1.42 1.42" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1 12h2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21 12h2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.22 19.78l1.42-1.42" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.36 5.64l1.42-1.42" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="12" cy="12" r="4" strokeWidth="2"/>
    <path d="M12 8v4l2 2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9 18c-4.4-1.1-7-5.6-7-10M22 8c-1.1-4.4-5.6-7-10-7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15 18c4.4-1.1 7-5.6 7-10M2 8c1.1-4.4 5.6-7 10-7" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 16c2.2 0 4-1.8 4-4s-1.8-4-4-4" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

const ServiceCard = ({ service }) => {
  const handleMouseMove = (e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    
    const rotateX = (y - centerY) / 20;
    const rotateY = -(x - centerX) / 20;
    
    card.style.transform = `
      perspective(1000px)
      rotateX(${rotateX}deg)
      rotateY(${rotateY}deg)
      translateZ(20px)
    `;
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = 'perspective(1000px) rotateX(0) rotateY(0) translateZ(0)';
  };

  return (
    <div 
      className={`service-card ${service.id}`}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div className="service-image">
        {service.isSvg ? <SupportIcon /> : (
          <img src={service.image} alt={service.title} />
        )}
      </div>
      <h3 className="service-title">{service.title}</h3>
      <p className="service-description">{service.description}</p>
    </div>
  );
};

const ServicesSection = () => {
  return (
    <section className="services-section">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={1}
        centeredSlides={true}
        loop={true}
        grabCursor={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {SERVICES.map(service => (
          <SwiperSlide key={service.id}>
            <ServiceCard service={service} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default ServicesSection;