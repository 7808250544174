import React from 'react';
import { Rocket, Building2, User, Users } from 'lucide-react';
import { Button } from '../../../../components/common/Button/Button';

const categories = [
  {
    title: 'Начинающим предпринимателям',
    icon: Rocket,
    features: [
      'Запуск системы привлечения клиентов с нуля',
      'Бюджет на старт от 30 000 ₽',
      'Результаты через 3-4 недели',
      'Обучение основам маркетинга'
    ]
  },
  {
    title: 'Растущему бизнесу',
    icon: Building2,
    features: [
      'Масштабирование работающих каналов',
      'Автоматизация маркетинга',
      'Внедрение сквозной аналитики',
      'Построение отдела продаж'
    ]
  },
  {
    title: 'Экспертам и специалистам',
    icon: User,
    features: [
      'Упаковка личного бренда',
      'Разработка контент-стратегии',
      'Запуск воронки продаж услуг',
      'Выход в премиум-сегмент'
    ]
  },
  {
    title: 'Маркетологам компаний',
    icon: Users,
    features: [
      'Усиление текущих инструментов',
      'Внедрение новых каналов',
      'Оптимизация бюджетов',
      'Автоматизация процессов'
    ]
  }
];

const ForWhom = () => {
  return (
    <section id="for-whom" className="py-20 bg-cream/30">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-2xl mx-auto mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-dark-coffee mb-6">
            Кому подойдет
          </h2>
          <p className="text-lg text-latte">
            Выберите свой путь развития в маркетинге
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {categories.map((category, index) => (
            <div
              key={index}
              className="group hover:scale-105 transition-transform duration-300"
            >
              <div className="h-full p-8 rounded-2xl bg-white shadow-xl border border-latte/10">
                <category.icon className="w-12 h-12 mb-6 text-caramel" />
                
                <h3 className="text-xl font-semibold text-dark-coffee mb-6">
                  {category.title}
                </h3>

                <ul className="space-y-4 mb-8">
                  {category.features.map((feature, idx) => (
                    <li key={idx} className="flex items-start text-latte">
                      <span className="w-1.5 h-1.5 rounded-full bg-caramel mr-3 mt-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center">
          <Button size="lg">
            Получить консультацию
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ForWhom;