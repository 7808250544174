import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Building2, Target, DollarSign, TrendingUp, ArrowRight } from 'lucide-react';
import { Button } from '../../../../components/common/Button/Button';

// Временные данные для примера
const caseData = {
  id: '1',
  title: 'Увеличение продаж онлайн-школы в 2.5 раза',
  description: 'Как мы помогли школе английского языка увеличить количество студентов и снизить стоимость привлечения',
  client: {
    name: 'English Pro',
    industry: 'Образование',
    location: 'Москва',
    size: '10-50 сотрудников'
  },
  challenge: `
    Онлайн-школа английского языка столкнулась с проблемой высокой стоимости привлечения студентов и низкой конверсии сайта. 
    Основные задачи:
    - Снижение стоимости привлечения
    - Увеличение конверсии сайта
    - Масштабирование рекламных каналов
  `,
  solution: `
    Мы разработали комплексную стратегию, которая включала:
    1. Редизайн основных посадочных страниц
    2. Внедрение новой воронки продаж
    3. Оптимизацию рекламных кампаний
    4. Запуск новых каналов привлечения
  `,
  results: {
    metrics: [
      { label: 'Рост выручки', value: '+147%' },
      { label: 'Снижение CPL', value: '-32%' },
      { label: 'Рост конверсии', value: '+85%' },
      { label: 'Новых студентов', value: '250+' }
    ],
    details: [
      {
        title: 'Трафик',
        before: '1,200',
        after: '3,800',
        unit: 'посетителей/мес'
      },
      {
        title: 'Конверсия',
        before: '2.5%',
        after: '4.6%',
        unit: 'CR в заявку'
      },
      {
        title: 'Стоимость лида',
        before: '3,500₽',
        after: '2,380₽',
        unit: 'CPL'
      }
    ]
  },
  images: [
    '/api/placeholder/1200/600',
    '/api/placeholder/800/600',
    '/api/placeholder/800/600'
  ],
  duration: '3 месяца',
  budget: '100-300т ₽/мес'
};

const CaseDetail = ({ caseId }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, [caseId]);

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="animate-pulse">
          <div className="h-8 bg-latte/20 rounded w-3/4 mb-4" />
          <div className="h-4 bg-latte/20 rounded w-1/2 mb-8" />
          <div className="aspect-video bg-latte/20 rounded-2xl" />
        </div>
      </div>
    );
  }

  return (
    <article className="container mx-auto px-4 sm:px-6 lg:px-8">
      {/* Навигация */}
      <div className="mb-8">
        <Link to="/cases">
          <Button variant="ghost" size="sm">
            <ArrowLeft className="w-4 h-4 mr-2" />
            Все кейсы
          </Button>
        </Link>
      </div>

      {/* Заголовок */}
      <div className="max-w-3xl mx-auto text-center mb-12">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-dark-coffee mb-6">
          {caseData.title}
        </h1>
        <p className="text-xl text-latte">
          {caseData.description}
        </p>
      </div>

      {/* Основное изображение */}
      <div className="max-w-4xl mx-auto mb-12">
        <div className="aspect-video rounded-2xl overflow-hidden">
          <img
            src={caseData.images[0]}
            alt={caseData.title}
            className="w-full h-full object-cover"
          />
        </div>
      </div>

      {/* Сетка с информацией */}
      <div className="max-w-4xl mx-auto grid md:grid-cols-2 gap-8 mb-16">
        {/* О клиенте */}
        <div className="p-8 bg-cream/30 rounded-2xl">
          <h3 className="flex items-center text-lg font-semibold text-dark-coffee mb-4">
            <Building2 className="w-5 h-5 mr-2" />
            О клиенте
          </h3>
          <div className="space-y-2 text-latte">
            <p>Компания: {caseData.client.name}</p>
            <p>Отрасль: {caseData.client.industry}</p>
            <p>Локация: {caseData.client.location}</p>
            <p>Размер: {caseData.client.size}</p>
          </div>
        </div>

        {/* Параметры проекта */}
        <div className="p-8 bg-cream/30 rounded-2xl">
          <h3 className="flex items-center text-lg font-semibold text-dark-coffee mb-4">
            <Target className="w-5 h-5 mr-2" />
            Параметры проекта
          </h3>
          <div className="space-y-2 text-latte">
            <p>Длительность: {caseData.duration}</p>
            <p>Бюджет: {caseData.budget}</p>
          </div>
        </div>
      </div>

      {/* Задача */}
      <div className="max-w-3xl mx-auto mb-16">
        <h2 className="text-2xl font-bold text-dark-coffee mb-6">Задача</h2>
        <div className="prose prose-lg prose-latte">
          <p>{caseData.challenge}</p>
        </div>
      </div>

      {/* Решение */}
      <div className="max-w-3xl mx-auto mb-16">
        <h2 className="text-2xl font-bold text-dark-coffee mb-6">Решение</h2>
        <div className="prose prose-lg prose-latte">
          <p>{caseData.solution}</p>
        </div>
      </div>

      {/* Результаты */}
      <div className="max-w-4xl mx-auto mb-16">
        <h2 className="text-2xl font-bold text-dark-coffee text-center mb-12">Результаты</h2>
        
        {/* Ключевые метрики */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-12">
          {caseData.results.metrics.map((metric, index) => (
            <div key={index} className="text-center">
              <div className="text-3xl font-bold text-caramel mb-2">
                {metric.value}
              </div>
              <div className="text-sm text-latte">
                {metric.label}
              </div>
            </div>
          ))}
        </div>

        {/* Детальные результаты */}
        <div className="grid md:grid-cols-3 gap-8">
          {caseData.results.details.map((detail, index) => (
            <div key={index} className="p-6 bg-cream/30 rounded-2xl">
              <h4 className="font-semibold text-dark-coffee mb-4">{detail.title}</h4>
              <div className="flex items-center justify-between">
                <div className="text-latte">
                  <div className="text-sm mb-1">Было</div>
                  <div className="text-lg">{detail.before}</div>
                </div>
                <ArrowRight className="w-5 h-5 text-caramel" />
                <div className="text-dark-coffee">
                  <div className="text-sm mb-1">Стало</div>
                  <div className="text-lg font-semibold">{detail.after}</div>
                </div>
              </div>
              <div className="text-sm text-latte mt-2">
                {detail.unit}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Галерея изображений */}
      <div className="max-w-4xl mx-auto mb-16">
        <div className="grid md:grid-cols-2 gap-8">
          {caseData.images.slice(1).map((image, index) => (
            <div key={index} className="rounded-2xl overflow-hidden">
              <img
                src={image}
                alt={`Результат ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>

      {/* CTA блок */}
      <div className="max-w-3xl mx-auto">
        <div className="text-center p-8 bg-cream/30 rounded-2xl">
          <h3 className="text-xl font-semibold text-dark-coffee mb-4">
            Хотите похожие результаты?
          </h3>
          <p className="text-latte mb-6">
            Закажите консультацию, и мы разработаем стратегию специально для вашего бизнеса
          </p>
          <Button size="lg">
            Заказать консультацию
          </Button>
        </div>
      </div>
    </article>
  );
};

export default CaseDetail;