// src/pages/CoffeeMarketing/components/FAQSection/index.jsx
import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './FAQSection.css';

gsap.registerPlugin(ScrollTrigger);

const FAQ_ITEMS = [
  {
    id: 1,
    question: 'Как оплатить консультацию?',
    answer: 'После выбора тарифа и согласования даты консультации вы получите ссылку на оплату. Оплата производится любым удобным способом: банковской картой, через СБП или безналичным переводом для юридических лиц.'
  },
  {
    id: 2,
    question: 'Какая информация нужна для подготовки анализа и стратегии?',
    answer: 'После предварительной консультации вы получите список необходимых материалов. Обычно это доступы к рекламным кабинетам, аналитике, информация о целях и задачах бизнеса, текущих проблемах и планируемых показателях.'
  },
  {
    id: 3,
    question: 'Как можно связаться с вами для уточнения вопросов после консультации?',
    answer: 'После консультации вы можете задавать вопросы по email или в Telegram. Для тарифа "ЭсПресс лонг" предусмотрено расширенное сопровождение в течение месяца с возможностью задать до 3 вопросов в неделю.'
  },
  {
    id: 4,
    question: 'Можно ли провести консультацию в офисе клиента?',
    answer: 'Да, такой формат возможен при выборе соответствующего тарифа. Встреча может быть проведена в вашем офисе в пределах города. Детали обсуждаются индивидуально на предварительной консультации.'
  }
];

const FAQSection = () => {
  const [activeId, setActiveId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const sectionRef = useRef(null);
  const headerRef = useRef(null);
  const searchRef = useRef(null);
  const itemsRef = useRef([]);

  useEffect(() => {
    // Анимация заголовка
    gsap.to(headerRef.current, {
      scrollTrigger: {
        trigger: headerRef.current,
        start: "top 80%",
      },
      opacity: 1,
      y: 0,
      duration: 0.8
    });

    // Анимация поисковой строки
    gsap.to(searchRef.current, {
      scrollTrigger: {
        trigger: searchRef.current,
        start: "top 80%",
      },
      opacity: 1,
      y: 0,
      duration: 0.8,
      delay: 0.2
    });

    // Анимация вопросов
    itemsRef.current.forEach((item, index) => {
      if (!item) return;
      gsap.to(item, {
        scrollTrigger: {
          trigger: item,
          start: "top 85%",
        },
        opacity: 1,
        y: 0,
        duration: 0.6,
        delay: 0.4 + (index * 0.1)
      });
    });

    // Создание декоративных элементов
    const section = sectionRef.current;
    const decorations = [];

    for(let i = 0; i < 10; i++) {
      // Кофейные зерна
      const bean = document.createElement('div');
      bean.className = 'coffee-bean';
      bean.style.left = `${Math.random() * 100}%`;
      bean.style.top = `${Math.random() * 100}%`;
      section.appendChild(bean);
      decorations.push(bean);

      gsap.to(bean, {
        rotation: Math.random() * 360,
        x: Math.random() * 100 - 50,
        y: Math.random() * 100 - 50,
        duration: Math.random() * 10 + 10,
        repeat: -1,
        yoyo: true,
        ease: "none"
      });

      // Декоративные круги
      const circle = document.createElement('div');
      circle.className = 'decoration-circle';
      circle.style.left = `${Math.random() * 100}%`;
      circle.style.top = `${Math.random() * 100}%`;
      circle.style.width = `${Math.random() * 50 + 20}px`;
      circle.style.height = circle.style.width;
      section.appendChild(circle);
      decorations.push(circle);

      gsap.to(circle, {
        scale: Math.random() * 0.5 + 1,
        duration: Math.random() * 5 + 5,
        repeat: -1,
        yoyo: true,
        ease: "none"
      });
    }

    return () => {
      decorations.forEach(element => element.remove());
    };
  }, []);

  const toggleQuestion = (id) => {
    setActiveId(activeId === id ? null : id);
  };

  const filteredFAQ = FAQ_ITEMS.filter(item => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item.question.toLowerCase().includes(searchLower) ||
      item.answer.toLowerCase().includes(searchLower)
    );
  });

  return (
    <section ref={sectionRef} className="faq-section">
      <div className="faq-container">
        <div ref={headerRef} className="section-header">
          <h2 className="section-title">Часто задаваемые вопросы</h2>
        </div>

        <div ref={searchRef} className="search-bar">
          <span className="search-icon">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
          </span>
          <input
            type="text"
            className="search-input"
            placeholder="Поиск по вопросам..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="faq-list">
          {filteredFAQ.map((item, index) => (
            <div
              key={item.id}
              ref={el => itemsRef.current[index] = el}
              className={`faq-item ${activeId === item.id ? 'active' : ''}`}
            >
              <div 
                className="faq-question"
                onClick={() => toggleQuestion(item.id)}
              >
                {item.question}
              </div>
              <div className="faq-answer">
                <div className="faq-answer-content">
                  {item.answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;