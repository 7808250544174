import React from 'react';
import { Coffee, MonitorSmartphone, Share2, ArrowRight, CheckCircle2 } from 'lucide-react';
import { Button } from '../../../../components/common/Button/Button';
import './Services.css';

const services = [
  {
    icon: Coffee,
    title: 'Кофе с маркетологом',
    price: '8 250 ₽',
    description: 'Персональная 2-часовая консультация для анализа вашего бизнеса и создания эффективной стратегии роста',
    features: [
      'Детальный анализ вашей ниши и конкурентов',
      'План внедрения маркетинговых инструментов',
      'Готовая стратегия продвижения',
      'PDF с пошаговыми действиями'
    ],
    benefits: [
      'Экономия до 100,000₽ на ошибках',
      'Готовый план действий уже через 2 часа',
      'Только проверенные инструменты'
    ]
  },
  {
    icon: MonitorSmartphone,
    title: 'Разработка сайта',
    price: 'от 40 000 ₽',
    description: 'Создание продающего сайта с учетом специфики вашего бизнеса и современных требований к конверсии',
    features: [
      'Адаптивный дизайн для всех устройств',
      'Интеграция с CRM и аналитикой',
      'SEO-оптимизация при разработке',
      'Высокая скорость загрузки'
    ],
    benefits: [
      'Рост конверсии до 300%',
      'Запуск сайта за 2 недели',
      'Техподдержка 3 месяца'
    ]
  },
  {
    icon: Share2,
    title: 'Соцсети под ключ',
    price: 'от 30 000 ₽',
    description: 'Комплексная упаковка ваших социальных сетей с обучением для самостоятельного ведения',
    features: [
      'Разработка контент-стратегии',
      'Создание визуального стиля',
      'Настройка автоматизации',
      'Обучение вашей команды'
    ],
    benefits: [
      'Экономия до 50% на SMM',
      'База готовых шаблонов',
      'Полная независимость от подрядчиков'
    ]
  }
];

const Services = () => {
  return (
    <section className="services-section">
      <div className="services-container">
        {/* Заголовок */}
        <div className="services-header">
          <h2>Наши услуги</h2>
          <p className="services-subtitle">
            Выберите подходящий формат сотрудничества и начните привлекать клиентов уже сегодня
          </p>
        </div>

        {/* Карточки услуг */}
        <div className="services-grid">
          {services.map((service, index) => (
            <div key={index} className="service-card">
              {/* Иконка и заголовок */}
              <div className="service-header">
                <div className="service-icon">
                  <service.icon size={24} />
                </div>
                <h3>{service.title}</h3>
                <div className="service-price">{service.price}</div>
              </div>

              {/* Описание */}
              <p className="service-description">
                {service.description}
              </p>

              {/* Что входит */}
              <div className="service-features">
                <h4>Что входит:</h4>
                <ul>
                  {service.features.map((feature, idx) => (
                    <li key={idx}>
                      <CheckCircle2 className="feature-icon" size={16} />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Преимущества */}
              <div className="service-benefits">
                <h4>Ваша выгода:</h4>
                <ul>
                  {service.benefits.map((benefit, idx) => (
                    <li key={idx}>
                      <div className="benefit-tag">
                        {benefit}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Кнопка */}
              <Button 
                className="service-button"
                onClick={() => {}}
              >
                Узнать подробнее
                <ArrowRight size={16} />
              </Button>
            </div>
          ))}
        </div>

        {/* Дополнительная информация */}
        <div className="services-info">
          <div className="info-card">
            <h4>Не знаете, что выбрать?</h4>
            <p>Запишитесь на бесплатную консультацию, и мы поможем определить оптимальное решение для вашего бизнеса</p>
            <Button variant="outline">
              Получить консультацию
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;