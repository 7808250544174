import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import NewsList from './components/NewsList';
import NewsDetail from './components/NewsDetail';

const News = () => {
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>{id ? 'Новость - ЭсПресс Маркетинг' : 'Новости - ЭсПресс Маркетинг'}</title>
        <meta 
          name="description" 
          content="Последние новости и обновления маркетингового агентства ЭсПресс" 
        />
      </Helmet>

      <div className="min-h-screen py-20">
        {id ? <NewsDetail newsId={id} /> : <NewsList />}
      </div>
    </>
  );
};

export default News;