import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';

const Blog = () => {
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>{id ? 'Статья - ЭсПресс Маркетинг' : 'Блог - ЭсПресс Маркетинг'}</title>
        <meta 
          name="description" 
          content="Полезные статьи о маркетинге, продвижении и развитии бизнеса" 
        />
      </Helmet>

      <div className="min-h-screen py-20">
        {id ? <BlogPost postId={id} /> : <BlogList />}
      </div>
    </>
  );
};

export default Blog;