import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowUpRight, Filter } from 'lucide-react';
import { Button } from '../../../../components/common/Button/Button';

// Временные данные для примера
const filters = {
  industry: ['Все отрасли', 'E-commerce', 'Образование', 'Услуги', 'Производство'],
  budget: ['Любой бюджет', 'до 50т', '50-100т', '100-300т', '300т+'],
  goal: ['Все цели', 'Продажи', 'Трафик', 'Узнаваемость', 'Лиды']
};

const cases = [
  {
    id: '1',
    title: 'Увеличение продаж онлайн-школы в 2.5 раза',
    description: 'Как мы помогли школе английского языка увеличить количество студентов и снизить стоимость привлечения',
    image: '/api/placeholder/800/600',
    industry: 'Образование',
    budget: '100-300т',
    goal: 'Продажи',
    results: {
      revenue: '+147%',
      leads: '+285%',
      cpl: '-32%'
    }
  },
  // Добавьте больше кейсов...
];

const CasesList = () => {
  const [selectedFilters, setSelectedFilters] = useState({
    industry: 'Все отрасли',
    budget: 'Любой бюджет',
    goal: 'Все цели'
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const filteredCases = cases.filter(item => {
    return (
      (selectedFilters.industry === 'Все отрасли' || item.industry === selectedFilters.industry) &&
      (selectedFilters.budget === 'Любой бюджет' || item.budget === selectedFilters.budget) &&
      (selectedFilters.goal === 'Все цели' || item.goal === selectedFilters.goal)
    );
  });

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      {/* Заголовок */}
      <div className="text-center max-w-2xl mx-auto mb-12">
        <h1 className="text-3xl md:text-4xl font-bold text-dark-coffee mb-6">
          Кейсы
        </h1>
        <p className="text-lg text-latte">
          Реальные истории успеха и результаты наших клиентов
        </p>
      </div>

      {/* Фильтры */}
      <div className="mb-12">
        <Button 
          variant="outline" 
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          className="md:hidden mb-4"
        >
          <Filter className="w-4 h-4 mr-2" />
          Фильтры
        </Button>

        <div className={`space-y-4 md:space-y-0 md:flex md:gap-6 ${isFilterOpen ? 'block' : 'hidden md:flex'}`}>
          {Object.entries(filters).map(([key, options]) => (
            <div key={key} className="flex-1">
              <select
                value={selectedFilters[key]}
                onChange={(e) => setSelectedFilters(prev => ({ ...prev, [key]: e.target.value }))}
                className="w-full h-10 rounded-md border border-latte bg-white px-3 text-sm focus:outline-none focus:ring-2 focus:ring-caramel focus:border-transparent"
              >
                {options.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
          ))}
        </div>
      </div>

      {/* Сетка кейсов */}
      <div className="grid md:grid-cols-2 gap-8">
        {filteredCases.map((item) => (
          <Link
            key={item.id}
            to={`/cases/${item.id}`}
            className="group"
          >
            <article className="h-full bg-white rounded-2xl shadow-lg overflow-hidden transition-transform duration-300 group-hover:-translate-y-2">
              {/* Изображение */}
              <div className="relative aspect-[4/3] overflow-hidden">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute top-4 left-4 flex gap-2">
                  <span className="px-3 py-1 bg-white/90 backdrop-blur-sm rounded-full text-sm text-dark-coffee">
                    {item.industry}
                  </span>
                  <span className="px-3 py-1 bg-white/90 backdrop-blur-sm rounded-full text-sm text-dark-coffee">
                    {item.goal}
                  </span>
                </div>
              </div>

              {/* Контент */}
              <div className="p-6">
                <h2 className="text-xl font-semibold text-dark-coffee mb-3 group-hover:text-caramel transition-colors">
                  {item.title}
                </h2>
                <p className="text-latte mb-6 line-clamp-2">
                  {item.description}
                </p>

                {/* Результаты */}
                <div className="grid grid-cols-3 gap-4 mb-6">
                  {Object.entries(item.results).map(([key, value]) => (
                    <div key={key} className="text-center">
                      <div className="text-xl font-bold text-caramel">
                        {value}
                      </div>
                      <div className="text-sm text-latte">
                        {key === 'revenue' ? 'Выручка' : 
                         key === 'leads' ? 'Лиды' : 
                         'Стоимость лида'}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Кнопка */}
                <div className="flex justify-end">
                  <span className="inline-flex items-center text-caramel group-hover:text-dark-coffee transition-colors">
                    Подробнее
                    <ArrowUpRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                  </span>
                </div>
              </div>
            </article>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CasesList;