import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Calendar, Tag, Share2, ArrowUpRight } from 'lucide-react';
import { Button } from '../../../../components/common/Button/Button';

// Временные данные для примера
const newsData = {
  id: '1',
  title: 'Запуск нового тарифа "ЭсПресс двойной лонг"',
  description: 'Теперь в тариф входит 3 занятия по работе с инструментами маркетинга и расширенная поддержка',
  content: `
    <h2>Что входит в новый тариф</h2>
    <p>Мы рады представить обновленный тариф "ЭсПресс двойной лонг", который включает расширенные возможности для наших клиентов. В рамках тарифа вы получаете не только стандартные консультации, но и практическое обучение работе с современными маркетинговыми инструментами.</p>
    
    <h3>Обучение инструментам</h3>
    <p>Теперь каждый клиент получает три практических занятия по 1,5 часа, на которых мы разбираем:</p>
    <ul>
      <li>Работу с нейронными сетями для создания контента и генерации идей</li>
      <li>Настройку и оптимизацию рекламных кампаний в VK</li>
      <li>Создание профессиональных визуалов в Canva</li>
    </ul>

    <h3>Расширенная поддержка</h3>
    <p>В течение месяца после консультации вы можете обращаться с дополнительными вопросами:</p>
    <ul>
      <li>3 консультации по телефону до 30 минут</li>
      <li>Неограниченное количество вопросов в чате</li>
      <li>Проверка настроек рекламных кампаний</li>
      <li>Помощь в работе с инструментами</li>
    </ul>

    <h3>Преимущества нового формата</h3>
    <p>Благодаря практическим занятиям вы не только получаете теоретические знания, но и сразу применяете их на практике под руководством эксперта. Это позволяет:</p>
    <ul>
      <li>Быстрее осваивать новые инструменты</li>
      <li>Избегать типичных ошибок начинающих</li>
      <li>Получать моментальную обратную связь</li>
      <li>Экономить время на самостоятельном изучении</li>
    </ul>

    <h3>Стоимость и бонусы</h3>
    <p>При оплате тарифа "ЭсПресс двойной лонг" вы дополнительно получаете доступ к нашей базе знаний с подробными инструкциями и видеоуроками по всем изучаемым инструментам.</p>
  `,
  image: '/api/placeholder/1200/600',
  date: '2024-03-20',
  category: 'Обновления',
  tags: ['Тарифы', 'Обучение', 'Поддержка'],
  author: {
    name: 'Мария Иванова',
    role: 'Руководитель отдела развития',
    avatar: '/api/placeholder/100/100'
  },
  relatedNews: [
    {
      id: '2',
      title: 'Обновление личного кабинета',
      image: '/api/placeholder/400/200',
      date: '2024-03-15'
    },
    {
      id: '3',
      title: 'Новые интеграции с CRM системами',
      image: '/api/placeholder/400/200',
      date: '2024-03-10'
    }
  ]
};

const NewsDetail = ({ newsId }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, [newsId]);

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="animate-pulse">
          <div className="h-8 bg-latte/20 rounded w-3/4 mb-4" />
          <div className="h-4 bg-latte/20 rounded w-1/2 mb-8" />
          <div className="aspect-[2/1] bg-latte/20 rounded-2xl" />
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      {/* Навигация */}
      <div className="mb-8">
        <Link to="/news">
          <Button variant="ghost" size="sm">
            <ArrowLeft className="w-4 h-4 mr-2" />
            Все новости
          </Button>
        </Link>
      </div>

      {/* Метаданные */}
      <div className="flex flex-wrap items-center gap-4 mb-6 text-latte">
        <span className="flex items-center">
          <Calendar className="w-4 h-4 mr-2" />
          {new Date(newsData.date).toLocaleDateString('ru-RU')}
        </span>
        <span className="flex items-center">
          <Tag className="w-4 h-4 mr-2" />
          {newsData.category}
        </span>
      </div>

      {/* Заголовок */}
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-dark-coffee mb-6">
        {newsData.title}
      </h1>
      
      <p className="text-xl text-latte mb-8">
        {newsData.description}
      </p>

      {/* Основное изображение */}
      <div className="aspect-[2/1] rounded-2xl overflow-hidden mb-12">
        <img
          src={newsData.image}
          alt={newsData.title}
          className="w-full h-full object-cover"
        />
      </div>

      {/* Автор */}
      <div className="flex items-center gap-4 p-6 bg-cream/30 rounded-2xl mb-12">
        <img
          src={newsData.author.avatar}
          alt={newsData.author.name}
          className="w-12 h-12 rounded-full"
        />
        <div>
          <div className="font-semibold text-dark-coffee">
            {newsData.author.name}
          </div>
          <div className="text-sm text-latte">
            {newsData.author.role}
          </div>
        </div>
      </div>

      {/* Контент */}
      <div 
        className="prose prose-lg prose-headings:text-dark-coffee prose-p:text-latte max-w-none mb-12"
        dangerouslySetInnerHTML={{ __html: newsData.content }}
      />

      {/* Теги */}
      <div className="flex flex-wrap gap-2 mb-12">
        {newsData.tags.map((tag) => (
          <span
            key={tag}
            className="px-4 py-2 bg-cream/30 rounded-full text-latte"
          >
            {tag}
          </span>
        ))}
      </div>

      {/* Поделиться */}
      <div className="flex items-center gap-4 mb-16">
        <span className="text-latte">Поделиться:</span>
        <Button variant="ghost" size="sm">
          <Share2 className="w-4 h-4 mr-2" />
          Поделиться
        </Button>
      </div>

      {/* Связанные новости */}
      {newsData.relatedNews.length > 0 && (
        <div className="border-t border-latte/10 pt-12">
          <h2 className="text-2xl font-bold text-dark-coffee mb-8">
            Читайте также
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {newsData.relatedNews.map((news) => (
              <Link
                key={news.id}
                to={`/news/${news.id}`}
                className="group block"
              >
                <div className="aspect-[2/1] rounded-xl overflow-hidden mb-4">
                  <img
                    src={news.image}
                    alt={news.title}
                    className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-semibold text-dark-coffee group-hover:text-caramel transition-colors">
                    {news.title}
                  </h3>
                  <ArrowUpRight className="w-5 h-5 text-caramel transform group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsDetail;