import React from 'react';
import { Helmet } from 'react-helmet-async';
import Hero from './components/Hero';
import Services from './components/Services';
import WorkProcess from './components/WorkProcess';
import ForWhom from './components/ForWhom';
import Methodology from './components/Methodology';
import  OurApproach from './components/OurApproach';
import ContactForm from './components/ContactForm';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>ЭсПресс Маркетинг - Системный маркетинг для бизнеса</title>
        <meta name="description" content="От первой заявки до стабильного потока клиентов. Профессиональное маркетинговое агентство." />
      </Helmet>
      
      <div className="flex flex-col gap-20 pb-20">
        <Hero />
        <Services />
        <WorkProcess />
        <ForWhom />
        <Methodology />
        < OurApproach />
        <ContactForm />
      </div>
    </>
  );
};

export default Home;