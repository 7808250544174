import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Coffee, Users, BarChart2, Target } from 'lucide-react';
import { Button } from '../../components/common/Button/Button';

const stats = [
  {
    value: '900+',
    label: 'успешно выполненных проектов',
    icon: Target,
    color: 'caramel'
  },
  {
    value: '80+',
    label: 'сертифицированных специалистов',
    icon: Users,
    color: 'dark-coffee'
  },
  {
    value: '11',
    label: 'лет опыта и экспертизы',
    icon: BarChart2,
    color: 'latte'
  },
  {
    value: '2',
    label: 'офиса в Москве и регионах',
    icon: Coffee,
    color: 'coffee-milk'
  }
];

const values = [
  {
    title: 'Профессиональный подход',
    description: 'Используем передовые технологии и стратегии для достижения целей'
  },
  {
    title: 'Опыт и экспертиза',
    description: 'В нашей команде квалифицированные специалисты-практики'
  },
  {
    title: 'Эффективность',
    description: 'Помогаем занять лидирующие позиции в короткие сроки'
  },
  {
    title: 'Быстрый фидбек',
    description: 'Оперативно проводим анализ и предлагаем релевантные решения'
  }
];

const About = () => {
  return (
    <>
      <Helmet>
        <title>О нас - ЭсПресс Маркетинг</title>
        <meta 
          name="description" 
          content="Маркетинговое агентство с 11-летним опытом в digital. Профессиональная команда, современные подходы, измеримые результаты." 
        />
      </Helmet>

      {/* Hero секция */}
      <section className="relative py-20 overflow-hidden">
        <div className="absolute inset-0 -z-10 bg-gradient-to-b from-cream to-white" />
        
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-dark-coffee mb-6">
              Маркетинговое агентство нового поколения
            </h1>
            <p className="text-xl text-latte mb-12">
              Объединяем системный подход, современные технологии и креативные решения для развития вашего бизнеса
            </p>
            <Button size="lg">Начать сотрудничество</Button>
          </div>
        </div>
      </section>

      {/* Статистика */}
      <section className="py-20">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div 
                key={index}
                className="text-center p-6 rounded-2xl bg-white shadow-lg border border-latte/10"
              >
                <div className={`w-16 h-16 rounded-full bg-${stat.color}/10 flex items-center justify-center mx-auto mb-4`}>
                  <stat.icon className={`w-8 h-8 text-${stat.color}`} />
                </div>
                <div className="text-3xl font-bold text-dark-coffee mb-2">
                  {stat.value}
                </div>
                <div className="text-latte">
                  {stat.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Наши ценности */}
      <section className="py-20 bg-cream/30">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-2xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-dark-coffee mb-6">
              Наши ценности
            </h2>
            <p className="text-lg text-latte">
              Принципы, которыми мы руководствуемся в работе
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            {values.map((value, index) => (
              <div 
                key={index}
                className="p-8 rounded-2xl bg-white shadow-lg border border-latte/10"
              >
                <h3 className="text-xl font-semibold text-dark-coffee mb-4">
                  {value.title}
                </h3>
                <p className="text-latte">
                  {value.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Почему мы */}
      <section className="py-20">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-dark-coffee mb-6">
                Почему клиенты выбирают нас
              </h2>
            </div>

            <div className="space-y-12">
              {/* Разносторонний опыт */}
              <div className="flex gap-6">
                <div className="w-16 h-16 rounded-full bg-caramel/10 flex-shrink-0 flex items-center justify-center">
                  <Target className="w-8 h-8 text-caramel" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-dark-coffee mb-4">
                    Разносторонний опыт
                  </h3>
                  <p className="text-latte">
                    Каждый день мы работаем с клиентами самой разной величины из самых разных отраслей. Это помогает нам видеть полную картину рынка и применять лучшие практики из разных сфер.
                  </p>
                </div>
              </div>

              {/* Взгляд со стороны */}
              <div className="flex gap-6">
                <div className="w-16 h-16 rounded-full bg-dark-coffee/10 flex-shrink-0 flex items-center justify-center">
                  <BarChart2 className="w-8 h-8 text-dark-coffee" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-dark-coffee mb-4">
                    Взгляд со стороны
                  </h3>
                  <p className="text-latte">
                    Нам проще увидеть то, что вы можете не замечать в силу разных причин. Свежий взгляд и экспертиза помогают находить новые возможности для роста.
                  </p>
                </div>
              </div>

              {/* Знания специалистов */}
              <div className="flex gap-6">
                <div className="w-16 h-16 rounded-full bg-latte/10 flex-shrink-0 flex items-center justify-center">
                  <Users className="w-8 h-8 text-latte" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-dark-coffee mb-4">
                    Знания специалистов
                  </h3>
                  <p className="text-latte">
                    Вы получите ответ от опытных специалистов, которые следят за самыми свежими тенденциями в области маркетинга и рекламы.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA секция */}
      <section className="py-20 bg-cream/30">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-dark-coffee mb-6">
              Готовы начать работу?
            </h2>
            <p className="text-lg text-latte mb-8">
              Запишитесь на консультацию, и мы поможем выбрать оптимальное решение для вашего бизнеса
            </p>
            <Button size="lg">
              Записаться на консультацию
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;