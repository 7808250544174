import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Search, Tag } from 'lucide-react';
import { Input } from '../../../../components/common/Input/Input';
import { Button } from '../../../../components/common/Button/Button';

// Временные данные для примера
const categories = [
  'Все статьи',
  'Маркетинг',
  'Продвижение',
  'Аналитика',
  'Стратегия',
  'Инструменты'
];

const posts = [
  {
    id: '1',
    title: 'Как составить эффективную маркетинговую стратегию в 2024 году',
    description: 'Пошаговое руководство по созданию маркетинговой стратегии с учетом современных трендов и инструментов',
    image: '/api/placeholder/800/400',
    category: 'Стратегия',
    date: '2024-03-15',
    readTime: '7 мин'
  },
  // Добавьте больше постов...
];

const BlogList = () => {
  const [selectedCategory, setSelectedCategory] = useState('Все статьи');
  const [searchQuery, setSearchQuery] = useState('');

  const filteredPosts = posts.filter(post => {
    const matchesCategory = selectedCategory === 'Все статьи' || post.category === selectedCategory;
    const matchesSearch = post.title.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      {/* Заголовок */}
      <div className="text-center max-w-2xl mx-auto mb-12">
        <h1 className="text-3xl md:text-4xl font-bold text-dark-coffee mb-6">
          Блог о маркетинге
        </h1>
        <p className="text-lg text-latte">
          Делимся опытом, инсайтами и полезными материалами
        </p>
      </div>

      {/* Фильтры и поиск */}
      <div className="mb-12">
        <div className="flex flex-col md:flex-row gap-6 items-center justify-between">
          {/* Категории */}
          <div className="flex flex-wrap gap-2">
            {categories.map((category) => (
              <Button
                key={category}
                variant={selectedCategory === category ? 'default' : 'outline'}
                size="sm"
                onClick={() => setSelectedCategory(category)}
              >
                <Tag className="w-4 h-4 mr-2" />
                {category}
              </Button>
            ))}
          </div>

          {/* Поиск */}
          <div className="w-full md:w-64">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-latte" />
              <Input
                type="text"
                placeholder="Поиск статей..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-10"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Сетка статей */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredPosts.map((post) => (
          <Link
            key={post.id}
            to={`/blog/${post.id}`}
            className="group"
          >
            <article className="bg-white rounded-2xl shadow-lg overflow-hidden transition-transform duration-300 group-hover:-translate-y-2">
              {/* Изображение */}
              <div className="relative aspect-[16/9] overflow-hidden">
                <img
                  src={post.image}
                  alt={post.title}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute top-4 left-4">
                  <span className="px-3 py-1 bg-white/90 backdrop-blur-sm rounded-full text-sm text-dark-coffee">
                    {post.category}
                  </span>
                </div>
              </div>

              {/* Контент */}
              <div className="p-6">
                <h2 className="text-xl font-semibold text-dark-coffee mb-3 group-hover:text-caramel transition-colors">
                  {post.title}
                </h2>
                <p className="text-latte mb-4 line-clamp-2">
                  {post.description}
                </p>
                <div className="flex items-center text-sm text-latte">
                  <time>{new Date(post.date).toLocaleDateString('ru-RU')}</time>
                  <span className="mx-2">•</span>
                  <span>{post.readTime}</span>
                </div>
              </div>
            </article>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogList;