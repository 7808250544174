// src/pages/CoffeeMarketing/components/SegmentsSection/index.jsx
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './SegmentsSection.css';

gsap.registerPlugin(ScrollTrigger);

const SEGMENTS = [
  {
    id: 'business',
    title: 'Руководителям бизнеса',
    description: 'Оптимизация маркетинговых процессов и увеличение эффективности рекламных инвестиций',
    icon: (
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
      </svg>
    ),
    features: [
      'Системный подход к маркетингу',
      'Оптимизация бюджетов',
      'Стратегическое планирование'
    ]
  },
  {
    id: 'marketers',
    title: 'Маркетологам',
    description: 'Развитие профессиональных компетенций и освоение новых инструментов',
    icon: (
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/>
      </svg>
    ),
    features: [
      'Практические кейсы',
      'Новые инструменты',
      'Эффективные стратегии'
    ]
  },
  {
    id: 'individuals',
    title: 'Индивидуальным мастерам',
    description: 'Личный бренд и эффективное продвижение услуг',
    icon: (
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
        <circle cx="12" cy="7" r="4"/>
      </svg>
    ),
    features: [
      'Построение личного бренда',
      'Привлечение клиентов',
      'Упаковка услуг'
    ]
  }
];

const SegmentsSection = () => {
  const sectionRef = useRef(null);
  const headerRef = useRef(null);
  const cardsRef = useRef([]);
  const telegramRef = useRef(null);

  useEffect(() => {
    // Анимация заголовка
    gsap.to(headerRef.current, {
      scrollTrigger: {
        trigger: headerRef.current,
        start: "top 80%",
      },
      opacity: 1,
      y: 0,
      duration: 0.8
    });

    // Анимация карточек
    cardsRef.current.forEach((card, index) => {
      gsap.to(card, {
        scrollTrigger: {
          trigger: card,
          start: "top 80%",
        },
        opacity: 1,
        y: 0,
        rotateY: 0,
        duration: 0.8,
        delay: index * 0.2
      });

      // Анимация фичей при наведении
      const features = card.querySelectorAll('.segment-feature');
      card.addEventListener('mouseenter', () => {
        features.forEach((feature, i) => {
          gsap.to(feature, {
            opacity: 1,
            x: 0,
            duration: 0.3,
            delay: i * 0.1
          });
        });
      });
    });

    // Анимация телеграм-блока
    gsap.to(telegramRef.current, {
      scrollTrigger: {
        trigger: telegramRef.current,
        start: "top 80%",
      },
      opacity: 1,
      y: 0,
      duration: 0.8
    });

    // Создание и анимация частиц
    const particles = Array.from({ length: 30 }, () => {
      const particle = document.createElement('div');
      particle.className = 'floating-particle';
      particle.style.left = `${Math.random() * 100}%`;
      particle.style.top = `${Math.random() * 100}%`;
      return particle;
    });

    const section = sectionRef.current;
    particles.forEach(particle => {
      section.appendChild(particle);
      gsap.to(particle, {
        x: Math.random() * 100 - 50,
        y: Math.random() * 100 - 50,
        duration: Math.random() * 10 + 10,
        repeat: -1,
        yoyo: true,
        ease: "none"
      });
    });

    // Пульсация иконки Telegram
    gsap.to('.telegram-icon', {
      scale: 1.1,
      duration: 1.5,
      repeat: -1,
      yoyo: true,
      ease: "power1.inOut"
    });

    return () => {
      particles.forEach(particle => particle.remove());
    };
  }, []);

  return (
    <section ref={sectionRef} className="segments-section">
      <div className="segments-container">
        <div ref={headerRef} className="section-header">
          <h2 className="section-title">Для кого встреча</h2>
        </div>

        <div className="segments-grid">
          {SEGMENTS.map((segment, index) => (
            <div
              key={segment.id}
              ref={el => cardsRef.current[index] = el}
              className="segment-card"
            >
              <div className="segment-icon">{segment.icon}</div>
              <h3 className="segment-title">{segment.title}</h3>
              <p className="segment-description">{segment.description}</p>
              <ul className="segment-features">
                {segment.features.map((feature, i) => (
                  <li
                    key={i}
                    className="segment-feature"
                    style={{ transitionDelay: `${0.1 * i}s` }}
                  >
                    <span className="feature-icon">✓</span>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div ref={telegramRef} className="telegram-block">
          <div className="telegram-content">
            <div className="telegram-icon">
              <svg width="40" height="40" viewBox="0 0 24 24" fill="currentColor" stroke="none">
                <path d="M22.05 1.577c-.393-.016-.784.08-1.117.235-.484.186-4.92 1.902-9.41 3.64-2.26.873-4.518 1.746-6.256 2.415-1.737.67-3.045 1.168-3.114 1.192-.46.16-1.082.362-1.61.984-.133.155-.267.354-.335.628s-.038.622.095.895c.265.547.714.773 1.244.976 1.76.564 3.58 1.102 5.087 1.608.556 1.96 1.09 3.927 1.618 5.89.174.394.553.54.944.544l-.002.02s.307.03.606-.042c.3-.07.677-.244 1.02-.565.377-.354 1.4-1.36 1.98-1.928l4.37 3.226.035.02s.484.34 1.192.388c.354.024.82-.044 1.22-.337.403-.294.67-.767.795-1.307.374-1.63 2.853-13.427 3.276-15.38l-.012.046c.296-1.1.187-2.108-.496-2.705-.342-.297-.736-.427-1.13-.444z"/>
              </svg>
            </div>
            <h3 className="telegram-title">Подписывайтесь на Телеграм-канал</h3>
            <div className="telegram-features">
              {[
                { icon: "📦", text: "Кейсы" },
                { icon: "🔔", text: "Новинки" },
                { icon: "💡", text: "Советы" }
              ].map((feature, index) => (
                <div key={index} className="telegram-feature">
                  <span className="feature-icon">{feature.icon}</span>
                  <span>{feature.text}</span>
                </div>
              ))}
            </div>
            <button 
              className="telegram-btn"
              onClick={() => window.open('https://t.me/esperessmarketing', '_blank')}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M2 12l5-5v3h14v4H7v3z"/>
              </svg>
              Подписаться
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SegmentsSection;