import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import CasesList from './components/CasesList';
import CaseDetail from './components/CaseDetail';

const Cases = () => {
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>{id ? 'Кейс - ЭсПресс Маркетинг' : 'Кейсы - ЭсПресс Маркетинг'}</title>
        <meta 
          name="description" 
          content="Реальные истории успеха наших клиентов. Кейсы по маркетингу и продвижению." 
        />
      </Helmet>

      <div className="min-h-screen py-20">
        {id ? <CaseDetail caseId={id} /> : <CasesList />}
      </div>
    </>
  );
};

export default Cases;

