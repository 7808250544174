import React, { useRef, useEffect } from 'react';
import { ArrowRight, Lightbulb, Target, Users, Gauge } from 'lucide-react';
import { Button } from '../../../../components/common/Button/Button';

const approaches = [
  {
    title: 'Индивидуальный подход',
    description: 'Детально изучаем ваш бизнес и создаем персональную стратегию развития',
    icon: Lightbulb,
    color: 'caramel'
  },
  {
    title: 'Ориентация на результат',
    description: 'Фокусируемся на достижении измеримых бизнес-показателей',
    icon: Target,
    color: 'dark-coffee'
  },
  {
    title: 'Открытая коммуникация',
    description: 'Всегда на связи и готовы ответить на любые вопросы',
    icon: Users,
    color: 'coffee-milk'
  },
  {
    title: 'Постоянная оптимизация',
    description: 'Регулярно анализируем результаты и улучшаем процессы',
    icon: Gauge,
    color: 'latte'
  }
];

const OurApproach = () => {
  const cardsRef = useRef([]);
  const containerRef = useRef(null);

  useEffect(() => {
    const updateCardsPosition = () => {
      if (!containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const scrollProgress = (containerRect.top - window.innerHeight) / -containerRect.height;

      cardsRef.current.forEach((card, index) => {
        if (!card) return;

        // Создаем эффект каскадного появления карточек при скролле
        const delay = index * 0.2;
        const progress = Math.max(0, Math.min(1, scrollProgress - delay));
        
        card.style.transform = `
          translateY(${20 - (progress * 20)}px)
          scale(${0.95 + (progress * 0.05)})
        `;
        card.style.opacity = progress;
      });
    };

    window.addEventListener('scroll', updateCardsPosition);
    updateCardsPosition();

    return () => window.removeEventListener('scroll', updateCardsPosition);
  }, []);

  return (
    <section className="relative py-20 overflow-hidden bg-gradient-to-b from-cream/30 to-white">
      {/* Декоративные элементы */}
      <div className="absolute inset-0 w-full h-full">
        <div className="absolute top-0 left-0 w-full h-20 bg-gradient-to-b from-white to-transparent" />
        <div className="absolute bottom-0 left-0 w-full h-20 bg-gradient-to-t from-white to-transparent" />
        <div className="absolute -left-10 top-1/3 w-40 h-40 bg-caramel/10 rounded-full blur-3xl animate-pulse" />
        <div className="absolute -right-10 top-2/3 w-40 h-40 bg-coffee-milk/10 rounded-full blur-3xl animate-pulse animation-delay-2000" />
      </div>

      <div ref={containerRef} className="container mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center max-w-2xl mx-auto mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-dark-coffee mb-6">
            Как мы работаем
          </h2>
          <p className="text-lg text-latte">
            Прозрачный процесс и системный подход к развитию вашего бизнеса
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {approaches.map((approach, index) => (
            <div
              key={index}
              ref={el => cardsRef.current[index] = el}
              className="opacity-0"
            >
              <div className="group relative h-full p-8 rounded-2xl bg-white backdrop-blur-xl shadow-lg border border-white/20 transition-all duration-500 hover:shadow-xl hover:-translate-y-1">
                {/* Декоративный фон */}
                <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-white/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                
                {/* Иконка */}
                <div className={`relative w-16 h-16 rounded-2xl bg-${approach.color}/10 flex items-center justify-center mb-6 transition-transform duration-500 group-hover:scale-110`}>
                  <approach.icon className={`w-8 h-8 text-${approach.color}`} />
                  <div className="absolute inset-0 rounded-2xl bg-white/0 group-hover:bg-white/10 transition-colors duration-500" />
                </div>

                {/* Контент */}
                <div className="relative">
                  <h3 className="text-xl font-semibold text-dark-coffee mb-4">
                    {approach.title}
                  </h3>
                  <p className="text-latte group-hover:text-dark-coffee transition-colors duration-300">
                    {approach.description}
                  </p>
                </div>

                {/* Декоративная линия */}
                <div className="absolute bottom-0 left-8 right-8 h-0.5 bg-gradient-to-r from-transparent via-caramel/20 to-transparent transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500" />
              </div>
            </div>
          ))}
        </div>

        {/* CTA секция */}
        <div className="text-center mt-16">
          <Button size="lg" className="group">
            Обсудить проект
            <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default OurApproach;