import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { 
  Coffee, MonitorSmartphone, Share2, 
  CheckCircle2, Clock, Target, 
  Zap, ArrowRight, MessageCircle 
} from 'lucide-react';
import { Button } from '../../components/common/Button/Button';
import './Services.css';

const services = [
  {
    id: 'coffee',
    icon: Coffee,
    title: 'Кофе с маркетологом',
    shortDesc: 'Персональная консультация для анализа вашего бизнеса',
    price: '8 250 ₽',
    duration: '2 часа',
    description: 'За 2 часа разберем ваш бизнес и создадим план развития',
    includes: [
      'Анализ ниши и конкурентов',
      'Аудит текущего маркетинга',
      'Разработка стратегии продвижения',
      'План внедрения инструментов',
      'PDF с пошаговыми действиями',
      '2 недели поддержки в чате'
    ],
    results: [
      'Экономия до 100,000₽ на ошибках',
      'Готовая стратегия через 2 часа',
      'Понимание эффективных каналов',
      'Оптимизация маркетингового бюджета'
    ],
    process: [
      'Заполнение брифа перед встречей',
      'Детальный разбор бизнеса',
      'Анализ конкурентов',
      'Разработка стратегии',
      'Ответы на все вопросы',
      'Подготовка итогового документа'
    ],
    link: '/coffee-marketing'
  },
  {
    id: 'website',
    icon: MonitorSmartphone,
    title: 'Разработка сайта',
    shortDesc: 'Создание продающего сайта для вашего бизнеса',
    price: 'от 40 000 ₽',
    duration: '2-3 недели',
    description: 'Создаем современные сайты, которые продают. Используем проверенные маркетинговые подходы и последние технологии для максимальной конверсии посетителей в клиентов.',
    includes: [
      'Прототип и дизайн сайта',
      'Адаптивная верстка',
      'SEO-оптимизация',
      'Интеграция с CRM',
      'Настройка аналитики',
      '3 месяца поддержки'
    ],
    results: [
      'Рост конверсии до 300%',
      'Увеличение среднего чека',
      'Автоматизация продаж',
      'Улучшение имиджа компании'
    ],
    process: [
      'Анализ ниши и конкурентов',
      'Разработка структуры',
      'Создание дизайна',
      'Верстка и программирование',
      'Наполнение контентом',
      'Тестирование и запуск'
    ]
  },
  {
    id: 'smm',
    icon: Share2,
    title: 'Соцсети под ключ',
    shortDesc: 'Упаковка соцсетей с обучением самостоятельному ведению',
    price: 'от 30 000 ₽',
    duration: '2 недели',
    description: 'Помогаем построить сильный личный бренд в социальных сетях. Создаем стратегию, упаковываем профиль и обучаем вашу команду самостоятельному ведению.',
    includes: [
      'Анализ целевой аудитории',
      'Разработка контент-стратегии',
      'Создание визуального стиля',
      'Настройка автоматизации',
      'Обучение команды',
      '1 месяц поддержки'
    ],
    results: [
      'Экономия до 50% на SMM',
      'Рост охватов и вовлеченности',
      'Увеличение продаж',
      'Независимость от подрядчиков'
    ],
    process: [
      'Аудит текущих соцсетей',
      'Разработка стратегии',
      'Создание гайдлайнов',
      'Подготовка шаблонов',
      'Обучение команды',
      'Поддержка и корректировки'
    ]
  }
];

const ServicesPage = () => {
  const [selectedService, setSelectedService] = useState(services[0]);

  const handleServiceClick = (service) => {
    if (service.link) {
      window.location.href = service.link;
    } else {
      setSelectedService(service);
    }
  };

  return (
    <>
      <Helmet>
        <title>Услуги - ЭсПресс Маркетинг</title>
        <meta 
          name="description" 
          content="Маркетинговые услуги для вашего бизнеса: консультации, разработка сайтов, ведение соцсетей"
        />
      </Helmet>

      <div className="services-page">
        <section className="services-hero">
          <div className="container">
            <h1>Наши услуги</h1>
            <p className="hero-subtitle">
              Выберите оптимальное решение для развития вашего бизнеса
            </p>
            
            <div className="services-nav">
              {services.map((service) => (
                <button
                  key={service.id}
                  className={`service-nav-card ${selectedService.id === service.id ? 'active' : ''}`}
                  onClick={() => handleServiceClick(service)}
                >
                  <service.icon className="nav-icon" />
                  <div className="nav-content">
                    <h3>{service.title}</h3>
                    <p>{service.shortDesc}</p>
                  </div>
                  <ArrowRight className="arrow-icon" />
                </button>
              ))}
            </div>
          </div>
        </section>

        <section className="service-details">
          <div className="container">
            <div className="service-header">
              <div className="service-title">
                <selectedService.icon className="service-icon" />
                <div>
                  <h2>{selectedService.title}</h2>
                  <div className="service-meta">
                    <span className="price">{selectedService.price}</span>
                    <span className="duration">
                      <Clock className="meta-icon" />
                      {selectedService.duration}
                    </span>
                  </div>
                </div>
              </div>
              <Button className="cta-button">
                <MessageCircle className="button-icon" />
                Заказать услугу
              </Button>
            </div>

            <div className="service-description">
              {selectedService.description}
            </div>

            <div className="service-grid">
              <div className="service-card">
                <h3>
                  <CheckCircle2 className="card-icon" />
                  Что входит
                </h3>
                <ul className="service-list">
                  {selectedService.includes.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>

              <div className="service-card">
                <h3>
                  <Target className="card-icon" />
                  Ваши результаты
                </h3>
                <ul className="service-list results-list">
                  {selectedService.results.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>

              <div className="service-card">
                <h3>
                  <Zap className="card-icon" />
                  Как мы работаем
                </h3>
                <ul className="service-list process-list">
                  {selectedService.process.map((item, index) => (
                    <li key={index}>
                      <span className="process-number">{index + 1}</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="services-cta">
          <div className="container">
            <div className="cta-content">
              <h2>Остались вопросы?</h2>
              <p>
                Запишитесь на бесплатную консультацию, и мы поможем выбрать 
                оптимальное решение для вашего бизнеса
              </p>
              <Button size="lg">
                Получить консультацию
                <ArrowRight className="button-icon" />
              </Button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ServicesPage;