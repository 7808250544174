// src/pages/CoffeeMarketing/index.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Hero from './components/Hero';
import ServicesSection from './components/ServicesSection';
import ProcessSection from './components/ProcessSection';
import AboutSection from './components/AboutSection';
import SegmentsSection from './components/SegmentsSection';
import PricingSection from './components/PricingSection';
import FAQSection from './components/FAQSection';

const CoffeeMarketing = () => {
  return (
    <>
      <Helmet>
        <title>Кофе с маркетологом | Анна Шпаковская</title>
        <meta 
          name="description" 
          content="Профессиональные консультации по маркетингу с Анной Шпаковской. Опыт более 8 лет, более 100 успешных проектов."
        />
      </Helmet>

      <div className="coffee-marketing">
        <Hero />
        <ServicesSection />
        <ProcessSection />
        <AboutSection />
        <SegmentsSection />
        <PricingSection />
        <FAQSection />
      </div>
    </>
  );
};

export default CoffeeMarketing;