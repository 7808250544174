// src/pages/CoffeeMarketing/components/AboutSection/index.jsx
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './AboutSection.css';

gsap.registerPlugin(ScrollTrigger);

const ACHIEVEMENTS = [
  { id: 1, number: 1000, text: 'часов консультаций проведено' },
  { id: 2, number: 20, text: 'различных ниш в портфолио' },
  { id: 3, number: 100, text: 'курсов и семинаров продано' }
];

const EXPERIENCE_ITEMS = [
  { id: 1, text: '8 лет опыта работы с длительными проектами' },
  { id: 2, text: 'Запустила 3 продукта на рынок с нуля' },
  { id: 3, text: 'Работала in-house, в агентстве и фрилансером' },
  { id: 4, text: 'Опыт работы с клиентами из России, США, Новой Зеландии и СНГ' },
  { id: 5, text: 'Была по ту сторону процесса. 2 года опыта в продажах, в том числе в крупных проектах блогеров миллионников' },
  { id: 6, text: 'С нуля, без вложений создала проект Brain&HeartMarketing. Удаленную команду из 5-ти человек. Проект успешно работал 4 года' }
];

const AboutSection = () => {
  const headerRef = useRef(null);
  const quoteRef = useRef(null);
  const achievementsRef = useRef([]);
  const experienceRef = useRef([]);

  useEffect(() => {
    // Анимация заголовка
    gsap.to(headerRef.current, {
      scrollTrigger: {
        trigger: headerRef.current,
        start: "top 80%",
      },
      opacity: 1,
      y: 0,
      duration: 0.8
    });

    // Анимация цитаты
    gsap.to(quoteRef.current, {
      scrollTrigger: {
        trigger: quoteRef.current,
        start: "top 80%",
      },
      opacity: 1,
      y: 0,
      duration: 0.8
    });

    // Анимация достижений
    achievementsRef.current.forEach((card, index) => {
      gsap.to(card, {
        scrollTrigger: {
          trigger: card,
          start: "top 85%",
        },
        opacity: 1,
        y: 0,
        duration: 0.8,
        delay: index * 0.2
      });

      // Анимация чисел
      const numberElement = card.querySelector('.achievement-number');
      ScrollTrigger.create({
        trigger: card,
        start: "top 85%",
        once: true,
        onEnter: () => {
          const target = ACHIEVEMENTS[index].number;
          let current = 0;
          const duration = 2000;
          const step = timestamp => {
            if (!start) start = timestamp;
            const progress = Math.min((timestamp - start) / duration, 1);
            current = Math.floor(progress * target);
            numberElement.textContent = current + '+';
            if (progress < 1) {
              window.requestAnimationFrame(step);
            }
          };
          let start = null;
          window.requestAnimationFrame(step);
        }
      });
    });

    // Анимация опыта
    experienceRef.current.forEach((item, index) => {
      gsap.to(item, {
        scrollTrigger: {
          trigger: item,
          start: "top 85%",
        },
        opacity: 1,
        x: 0,
        duration: 0.6,
        delay: index * 0.1,
        ease: "power2.out"
      });
    });

    // Анимированные частицы
    const particles = Array.from({ length: 25 }, () => {
      const particle = document.createElement('div');
      particle.className = 'coffee-particle';
      particle.style.width = `${Math.random() * 10 + 5}px`;
      particle.style.height = particle.style.width;
      particle.style.left = `${Math.random() * 100}%`;
      particle.style.top = `${Math.random() * 100}%`;
      return particle;
    });

    particles.forEach(particle => {
      document.querySelector('.about-section').appendChild(particle);
      gsap.to(particle, {
        x: Math.random() * 100 - 50,
        y: Math.random() * 100 - 50,
        duration: Math.random() * 10 + 10,
        repeat: -1,
        yoyo: true,
        ease: "none"
      });
    });

    return () => {
      particles.forEach(particle => particle.remove());
    };
  }, []);

  return (
    <section className="about-section">
      <div className="about-container">
        <div ref={headerRef} className="about-header">
          <h2 className="about-title">Немного обо мне</h2>
        </div>

        <div ref={quoteRef} className="quote-block">
          <div className="quote-container">
            <div className="quote-line"></div>
            <div className="quote-text">
              Анна, рекламу останавливаем, мы не успеваем обработать всех
            </div>
          </div>
        </div>

        <div className="achievements-grid">
          {ACHIEVEMENTS.map((achievement, index) => (
            <div
              key={achievement.id}
              ref={el => achievementsRef.current[index] = el}
              className="achievement-card"
            >
              <div className="achievement-number">0</div>
              <div className="achievement-text">{achievement.text}</div>
            </div>
          ))}
        </div>

        <div className="experience-list">
          {EXPERIENCE_ITEMS.map((item, index) => (
            <div
              key={item.id}
              ref={el => experienceRef.current[index] = el}
              className="experience-item"
            >
              <div className="experience-icon">✓</div>
              <div className="experience-text">{item.text}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutSection;