import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow pt-[70px] w-full max-w-[] mx-auto px-">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;