import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Phone, 
  Mail, 
  MapPin, 
  MessageCircle,
  ArrowUpRight,
  Coffee,
  Package,
  Share2,
  HeadphonesIcon,
  Users,
  Briefcase,
  BookOpen,
  ContactIcon
} from 'lucide-react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const services = [
    { name: 'Кофе с маркетологом', path: '/services#coffee', icon: Coffee },
    { name: 'Упаковка под ключ', path: '/services#package', icon: Package },
    { name: 'Ведение соцсетей', path: '/services#smm', icon: Share2 },
    { name: 'Консультации', path: '/services#consult', icon: HeadphonesIcon }
  ];

  const company = [
    { name: 'О нас', path: '/about', icon: Users },
    { name: 'Кейсы', path: '/cases', icon: Briefcase },
    { name: 'Блог', path: '/blog', icon: BookOpen },
    { name: 'Контакты', path: '/contact', icon: ContactIcon }
  ];

  return (
    <footer className="footer-wrapper">
      <div className="footer-content">
        {/* Основной контент футера */}
        <div className="footer-main">
          {/* Информация о компании */}
          <div className="footer-company-info">
            <Link to="/" className="footer-logo">
              <span className="text-caramel">Эс</span>Пресс
            </Link>
            <p className="footer-description">
              Системный маркетинг для вашего бизнеса. 
              От первой заявки до стабильного потока клиентов.
            </p>
            <div className="footer-buttons">
              <a 
                href="https://t.me/your_channel" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="contact-button"
              >
                <MessageCircle className="w-5 h-5" />
                <span>Написать в Telegram</span>
                <ArrowUpRight className="w-4 h-4" />
              </a>
            </div>
          </div>

          {/* Услуги */}
          <div className="footer-nav">
            <h3>Услуги</h3>
            <ul>
              {services.map((item) => (
                <li key={item.name}>
                  <Link to={item.path}>
                    <item.icon className="nav-icon" />
                    <span>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Компания */}
          <div className="footer-nav">
            <h3>Компания</h3>
            <ul>
              {company.map((item) => (
                <li key={item.name}>
                  <Link to={item.path}>
                    <item.icon className="nav-icon" />
                    <span>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Контакты */}
          <div className="footer-contacts">
            <h3>Контакты</h3>
            <ul>
              <li>
                <Phone className="contact-icon" />
                <a href="tel:+79999999999">+7 (999) 999-99-99</a>
              </li>
              <li>
                <Mail className="contact-icon" />
                <a href="mailto:info@espress.ru">info@espress.ru</a>
              </li>
              <li>
                <MapPin className="contact-icon" />
                <span>Москва, ул. Примерная, 123</span>
              </li>
            </ul>
            <div className="social-links">
              <a 
                href="https://vk.com/your_group" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="social-link"
                aria-label="VKontakte"
              >
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.07 2H8.93C3.33 2 2 3.33 2 8.93V15.07C2 20.67 3.33 22 8.93 22H15.07C20.67 22 22 20.67 22 15.07V8.93C22 3.33 20.67 2 15.07 2ZM18.15 16.27H16.69C16.14 16.27 15.97 15.82 14.86 14.72C13.86 13.77 13.49 13.67 13.27 13.67C12.95 13.67 12.87 13.76 12.87 14.18V15.77C12.87 16.1 12.75 16.27 11.72 16.27C10.89 11.74 9.07 10.47 8.66 10.47C8.54 10.47 8.5 10.52 8.5 10.73V13.79C8.5 14.13 8.41 14.31 7.83 14.31C7.09 14.31 6.27 13.9 5.57 13.19C4.44 12.04 3.5 10.38 3.5 10.19C3.5 10.07 3.57 9.97 3.79 9.97H5.25C5.6 9.97 5.7 10.1 5.82 10.38C6.36 11.8 7.36 13.06 7.73 13.06C7.89 13.06 7.93 12.98 7.93 12.64V10.93C7.87 9.94 7.24 9.83 7.24 9.5C7.24 9.4 7.33 9.28 7.47 9.28H9.87C10.16 9.28 10.23 9.4 10.23 9.66V12.33C10.23 12.6 10.32 12.69 10.39 12.69C10.55 12.69 10.67 12.6 10.96 12.31C11.98 11.18 12.72 9.47 12.72 9.47C12.81 9.32 12.94 9.17 13.29 9.17H14.75C15.17 9.17 15.24 9.35 15.17 9.61C15 10.31 13.43 12.55 13.43 12.55C13.29 12.8 13.25 12.88 13.43 13.11C13.55 13.27 14.04 13.7 14.37 14.08C15.04 14.84 15.56 15.49 15.71 15.84C15.86 16.2 15.67 16.27 15.07 16.27Z"/>
                </svg>
              </a>
              <a 
                href="https://t.me/your_channel" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="social-link"
                aria-label="Telegram"
              >
                <svg
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16.64 8.8C16.49 9.89 15.23 14.77 14.59 16.98C14.31 17.94 13.77 18.23 13.27 18.25C12.19 18.3 11.36 17.47 10.33 16.77C8.72 15.69 7.83 15.02 6.28 13.98C4.5 12.77 5.64 12.1 6.66 11.04C6.95 10.74 11.11 6.95 11.21 6.6C11.22 6.56 11.23 6.44 11.16 6.38C11.09 6.32 10.98 6.34 10.9 6.35C10.79 6.37 8.55 7.9 4.19 10.94C3.57 11.36 3.01 11.56 2.53 11.55C1.99 11.53 0.96 11.22 0.22 10.96C-0.68 10.63 -0.65 10.02 0.16 9.69C5.06 7.72 8.32 6.41 9.93 5.76C14.59 3.96 15.57 3.61 16.21 3.61C16.36 3.61 16.69 3.64 16.91 3.82C17.08 3.96 17.15 4.15 17.16 4.31C17.15 4.41 17.18 4.77 16.64 8.8Z"/>
                </svg>
              </a>
            </div>
          </div>
        </div>

        {/* Нижняя часть футера */}
        <div className="footer-bottom">
          <div className="copyright">
            © {currentYear} ЭсПресс. Все права защищены.
          </div>
          <div className="footer-links">
            <Link to="/privacy">Политика конфиденциальности</Link>
            <Link to="/terms">Условия использования</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;