import React from 'react';
import { ArrowRight, Coffee } from 'lucide-react';
import { Button } from '../../../../components/common/Button/Button';
import { scrollToElement } from '../../../../utils/helpers';
import './Hero.css';

const metrics = [
  {
    title: 'Анализ ниши и конкурентов',
    value: '2 недели',
    icon: '📊'
  },
  {
    title: 'Запуск первых инструментов',
    value: '3 недели',
    icon: '🚀'
  },
  {
    title: 'Еженедельная отчетность',
    value: 'в цифрах',
    icon: '📈'
  },
  {
    title: 'План развития',
    value: '6 месяцев',
    icon: '🎯'
  }
];

const Hero = () => {
  return (
    <section className="relative min-h-[90vh] flex items-center py-20 overflow-hidden">
      {/* Фиксированный бэкграунд */}
      <div className="fixed inset-0 -z-20 bg-gradient-to-b from-cream via-cream/50 to-white" />
      
      {/* Анимированные элементы фона */}
      <div className="fixed inset-0 -z-10 overflow-hidden">
        {/* Большие анимированные круги */}
        <div 
          className="absolute w-[500px] h-[500px] bg-caramel/10 rounded-full blur-3xl animate-blob"
          style={{ 
            top: '20%', 
            left: '30%', 
            animationDelay: '0s',
            transform: 'translate(-50%, -50%)'
          }} 
        />
        <div 
          className="absolute w-[500px] h-[500px] bg-dark-coffee/10 rounded-full blur-3xl animate-blob"
          style={{ 
            top: '50%', 
            right: '30%', 
            animationDelay: '2s',
            transform: 'translate(50%, -50%)'
          }} 
        />
        <div 
          className="absolute w-[500px] h-[500px] bg-coffee-milk/20 rounded-full blur-3xl animate-blob"
          style={{ 
            bottom: '20%', 
            left: '50%', 
            animationDelay: '4s',
            transform: 'translate(-50%, 50%)'
          }} 
        />

        {/* Маленькие декоративные элементы */}
        <div 
          className="absolute w-8 h-8 border-2 border-caramel/30 rounded-full animate-spin-slow"
          style={{ top: '15%', left: '15%' }}
        />
        <div 
          className="absolute w-6 h-6 border-2 border-dark-coffee/30 rounded-full animate-spin-slow"
          style={{ bottom: '20%', right: '20%', animationDelay: '2s' }}
        />
        <div 
          className="absolute w-4 h-4 bg-caramel/30 rounded-full animate-pulse"
          style={{ top: '50%', right: '10%' }}
        />
      </div>

      <div className="container relative mx-auto px-4 sm:px-6 lg:px-8">
        {/* Стеклянная карточка */}
        <div className="relative backdrop-blur-xl bg-white/30 rounded-3xl border border-white/20 shadow-2xl p-8 md:p-12">
          {/* Блик на стекле */}
          <div className="absolute top-0 left-0 w-full h-full overflow-hidden rounded-3xl">
            <div className="absolute -top-1/2 -left-1/2 w-[200%] h-[200%] bg-gradient-to-br from-white/50 to-transparent rotate-12 pointer-events-none" />
          </div>
          
          <div className="relative z-10">
            <div className="text-center max-w-4xl mx-auto mb-12">
              {/* Основной контент */}
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-dark-coffee mb-6 animate-fade-in">
                Системный маркетинг для бизнеса
              </h1>
              
              <p className="text-xl md:text-2xl text-latte mb-12 animate-fade-in animation-delay-200">
                От первой заявки до стабильного потока клиентов
              </p>

              {/* Кнопки */}
              <div className="flex flex-col sm:flex-row gap-4 justify-center mb-16 animate-fade-in animation-delay-400">
                <Button 
                  size="lg"
                  onClick={() => scrollToElement('contact-form')}
                  className="group relative overflow-hidden"
                >
                  <span className="relative z-10">Получить план развития</span>
                  <div className="absolute inset-0 bg-dark-coffee transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
                  <ArrowRight className="relative z-10 ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
                </Button>
                
                <Button 
                  variant="outline"
                  size="lg"
                  onClick={() => scrollToElement('work-formats')}
                  className="group relative overflow-hidden"
                >
                  <span className="relative z-10">Форматы работы</span>
                  <div className="absolute inset-0 bg-caramel/10 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
                  <Coffee className="relative z-10 ml-2 h-5 w-5 transition-transform group-hover:rotate-12" />
                </Button>
              </div>

              {/* Метрики */}
              <div className="grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-8">
                {metrics.map((metric, index) => (
                  <div 
                    key={index}
                    className="p-6 rounded-2xl backdrop-blur-sm bg-white/40 border border-white/20 shadow-lg transform hover:-translate-y-1 transition-all duration-300 animate-fade-in"
                    style={{ animationDelay: `${(index + 3) * 200}ms` }}
                  >
                    <div className="text-4xl mb-3 animate-bounce-in" style={{ animationDelay: `${(index + 4) * 200}ms` }}>
                      {metric.icon}
                    </div>
                    <div className="font-semibold text-dark-coffee mb-2">
                      {metric.value}
                    </div>
                    <div className="text-sm text-latte">
                      {metric.title}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;