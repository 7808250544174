import React, { useState } from 'react';
import { Button } from '../../../../components/common/Button/Button';
import { Input } from '../../../../components/common/Input/Input';

const budgetOptions = [
  'до 30 000 ₽',
  '30 000 - 50 000 ₽',
  '50 000 - 100 000 ₽',
  'более 100 000 ₽'
];

const ContactForm = () => {
  const [formData, setFormData] = useState({
    business: '',
    budget: '',
    goal: '',
    phone: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Здесь будет логика отправки формы
    console.log(formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <section id="contact-form" className="py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <div className="relative">
            {/* Декоративные элементы */}
            <div className="absolute -left-4 -top-4 w-72 h-72 bg-caramel/5 rounded-full blur-3xl" />
            <div className="absolute -right-4 -bottom-4 w-72 h-72 bg-latte/5 rounded-full blur-3xl" />

            {/* Основная карточка */}
            <div className="relative bg-white rounded-2xl p-8 md:p-12 shadow-xl border border-latte/10">
              <div className="text-center mb-12">
                <h2 className="text-3xl md:text-4xl font-bold text-dark-coffee mb-6">
                  Получить план развития маркетинга
                </h2>
                <p className="text-lg text-latte">
                  Заполните форму, и мы подготовим персональный план развития за 24 часа
                </p>
              </div>

              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Сфера бизнеса */}
                <div>
                  <label className="block text-sm font-medium text-dark-coffee mb-2">
                    Сфера бизнеса
                  </label>
                  <Input
                    name="business"
                    value={formData.business}
                    onChange={handleChange}
                    placeholder="Например: онлайн-школа"
                    required
                  />
                </div>

                {/* Рекламный бюджет */}
                <div>
                  <label className="block text-sm font-medium text-dark-coffee mb-2">
                    Текущий рекламный бюджет
                  </label>
                  <select
                    name="budget"
                    value={formData.budget}
                    onChange={handleChange}
                    className="w-full h-10 rounded-md border border-latte bg-white px-3 text-sm focus:outline-none focus:ring-2 focus:ring-caramel focus:border-transparent"
                    required
                  >
                    <option value="">Выберите бюджет</option>
                    {budgetOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Главная цель */}
                <div>
                  <label className="block text-sm font-medium text-dark-coffee mb-2">
                    Главная цель на 3 месяца
                  </label>
                  <Input
                    name="goal"
                    value={formData.goal}
                    onChange={handleChange}
                    placeholder="Например: увеличить продажи в 2 раза"
                    required
                  />
                </div>

                {/* Телефон */}
                <div>
                  <label className="block text-sm font-medium text-dark-coffee mb-2">
                    Телефон для связи
                  </label>
                  <Input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="+7 (___) ___-__-__"
                    required
                  />
                </div>

                {/* Кнопка отправки */}
                <Button type="submit" size="lg" className="w-full">
                  Получить план развития за 24 часа
                </Button>

                {/* Дисклеймер */}
                <p className="text-sm text-latte text-center mt-4">
                  Нажимая на кнопку, вы соглашаетесь с политикой конфиденциальности
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;