
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { Button } from '../../../../components/common/Button/Button';
import './Hero.css';

const STATS = [
  { id: 1, value: 8, label: 'лет опыта' },
  { id: 2, value: 100, label: 'довольных клиентов' },
  { id: 3, value: 156, label: 'средний рост продаж' }
];

const Hero = () => {
  const containerRef = useRef(null);
  const photoRef = useRef(null);
  const statsRef = useRef(null);

  useEffect(() => {
    const background = document.getElementById('floatingBg');
    if (!background) return;

    background.innerHTML = '';

    const sizes = [100, 150, 200, 250];
    Array.from({ length: 6 }).forEach((_, i) => {
      const element = document.createElement('div');
      element.className = 'floating-element';
      element.style.width = `${sizes[i % sizes.length]}px`;
      element.style.height = `${sizes[i % sizes.length]}px`;
      element.style.left = `${Math.random() * 80}%`;
      element.style.top = `${Math.random() * 80}%`;
      element.style.animationDelay = `${Math.random() * 5}s`;
      element.style.animationDuration = `${20 + Math.random() * 10}s`;
      background.appendChild(element);
    });

    return () => {
      if (background) background.innerHTML = '';
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!containerRef.current || !photoRef.current) return;

      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;
      
      gsap.to(photoRef.current, {
        rotateX: (y - centerY) / 20,
        rotateY: -(x - centerX) / 20,
        translateZ: '20px',
        duration: 0.3,
        ease: 'power2.out'
      });
    };

    const handleMouseLeave = () => {
      if (!photoRef.current) return;

      gsap.to(photoRef.current, {
        rotateX: 0,
        rotateY: 0,
        translateZ: 0,
        duration: 0.5,
        ease: 'power2.out'
      });
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('mousemove', handleMouseMove);
      container.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (container) {
        container.removeEventListener('mousemove', handleMouseMove);
        container.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    if (!statsRef.current) return;

    const stats = statsRef.current.querySelectorAll('.stat-number');
    stats.forEach((stat, index) => {
      const value = STATS[index].value;
      gsap.to(stat, {
        textContent: value,
        duration: 2,
        ease: 'power1.inOut',
        snap: { textContent: 1 },
        scrollTrigger: {
          trigger: stat,
          start: 'top 80%',
        }
      });
    });
  }, []);

  return (
    <section className="min-h-[calc(100vh-70px)] grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 p-4 md:p-8 lg:p-12 relative">
      <div className="floating-background" id="floatingBg" />
      <div className="flex flex-col justify-center space-y-8 lg:space-y-12 order-2 lg:order-1">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold bg-gradient-to-r from-dark-coffee to-caramel bg-clip-text text-transparent">
          Кофе с маркетологом
        </h1>

        <p className="text-lg md:text-xl text-latte max-w-2xl">
          20+ длительных проектов и без горького послевкусия. 
          Я, Анна Шпаковская, знаю, как выстроить маркетинг в вашем проекте.
        </p>

        <Button
          variant="default"
          size="lg"
          onClick={() => window.open('https://t.me/annatelepan', '_blank')}
          className="w-fit hover:scale-105 transition-transform"
        >
          Обсудить проект
        </Button>

        <div ref={statsRef} className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {STATS.map((stat, index) => (
            <div 
              key={stat.id}
              className="bg-white/90 backdrop-blur-md rounded-xl p-6 text-center transform transition-all duration-300 hover:scale-105"
              style={{ animationDelay: `${0.2 * index}s` }}
            >
              <div className="stat-number text-3xl font-bold text-dark-coffee">0</div>
              <div className="text-latte mt-2">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="order-1 lg:order-2">
        <div className="relative w-full pt-[100%] md:pt-[90%] lg:pt-[80%]">
          <div 
            ref={containerRef}
            className="absolute inset-0"
            style={{ perspective: '2000px' }}
          >
            <div 
              ref={photoRef}
              className="photo-card"
            >
              <img 
                src="https://static.tildacdn.com/tild6132-6538-4639-b939-306661633533/noroot.png"
                alt="Анна Шпаковская - Эксперт по маркетингу"
                className="w-full h-full object-cover"
                loading="eager"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;