import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Calendar, Clock, Tag } from 'lucide-react';
import { Button } from '../../../../components/common/Button/Button';

// Временные данные для примера
const post = {
  id: '1',
  title: 'Как составить эффективную маркетинговую стратегию в 2024 году',
  description: 'Пошаговое руководство по созданию маркетинговой стратегии с учетом современных трендов и инструментов',
  content: `
    <h2>Введение</h2>
    <p>В современном мире маркетинговая стратегия является ключевым элементом успеха любого бизнеса...</p>
    
    <h2>1. Анализ рынка</h2>
    <p>Первым шагом в создании эффективной стратегии является детальный анализ рынка...</p>
    
    <h2>2. Определение целевой аудитории</h2>
    <p>Точное понимание вашей целевой аудитории позволит...</p>
  `,
  image: '/api/placeholder/1200/600',
  category: 'Стратегия',
  date: '2024-03-15',
  readTime: '7 мин',
  author: {
    name: 'Анна Петрова',
    role: 'Ведущий маркетолог',
    avatar: '/api/placeholder/100/100'
  }
};

const BlogPost = ({ postId }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Имитация загрузки данных
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [postId]);

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="animate-pulse">
          {/* Скелетон загрузки */}
          <div className="h-8 bg-latte/20 rounded w-3/4 mb-4" />
          <div className="h-4 bg-latte/20 rounded w-1/2 mb-8" />
          <div className="aspect-[2/1] bg-latte/20 rounded-2xl mb-8" />
          <div className="space-y-4">
            <div className="h-4 bg-latte/20 rounded w-full" />
            <div className="h-4 bg-latte/20 rounded w-5/6" />
            <div className="h-4 bg-latte/20 rounded w-4/6" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <article className="container mx-auto px-4 sm:px-6 lg:px-8">
      {/* Навигация */}
      <div className="mb-8">
        <Link to="/blog">
          <Button variant="ghost" size="sm">
            <ArrowLeft className="w-4 h-4 mr-2" />
            Вернуться к списку
          </Button>
        </Link>
      </div>

      {/* Заголовок */}
      <div className="max-w-3xl mx-auto text-center mb-12">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-dark-coffee mb-6">
          {post.title}
        </h1>
        <div className="flex items-center justify-center gap-4 text-latte">
          <span className="flex items-center">
            <Calendar className="w-4 h-4 mr-2" />
            {new Date(post.date).toLocaleDateString('ru-RU')}
          </span>
          <span className="flex items-center">
            <Clock className="w-4 h-4 mr-2" />
            {post.readTime}
          </span>
          <span className="flex items-center">
            <Tag className="w-4 h-4 mr-2" />
            {post.category}
          </span>
        </div>
      </div>

      {/* Изображение */}
      <div className="max-w-4xl mx-auto mb-12">
        <div className="aspect-[2/1] rounded-2xl overflow-hidden">
          <img
            src={post.image}
            alt={post.title}
            className="w-full h-full object-cover"
          />
        </div>
      </div>

      {/* Автор */}
      <div className="max-w-3xl mx-auto mb-12">
        <div className="flex items-center gap-4 p-6 bg-cream/30 rounded-2xl">
          <img
            src={post.author.avatar}
            alt={post.author.name}
            className="w-16 h-16 rounded-full object-cover"
          />
          <div>
            <div className="font-semibold text-dark-coffee">
              {post.author.name}
            </div>
            <div className="text-latte">
              {post.author.role}
            </div>
          </div>
        </div>
      </div>

      {/* Контент */}
      <div className="max-w-3xl mx-auto prose prose-lg prose-headings:text-dark-coffee prose-p:text-latte prose-a:text-caramel">
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>

      {/* CTA в конце статьи */}
      <div className="max-w-3xl mx-auto mt-12 p-8 bg-cream/30 rounded-2xl text-center">
        <h3 className="text-xl font-semibold text-dark-coffee mb-4">
          Хотите применить эти знания в своем бизнесе?
        </h3>
        <p className="text-latte mb-6">
          Запишитесь на консультацию, и мы поможем внедрить эти стратегии
        </p>
        <Button size="lg">
          Записаться на консультацию
        </Button>
      </div>
    </article>
  );
};

export default BlogPost;